import { createStyles, FormControl, makeStyles, MenuItem, Select, Tooltip } from '@material-ui/core';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { ReactComponent as FirstLegTrip } from '../../assets/images/first-leg-trip.svg';
import { ReactComponent as SecondLegTrip } from '../../assets/images/second-leg-trip.svg';
import AddButton from '../../components/CustomButtons/AddButton';
import Button from '../../components/CustomButtons/Button';
import CustomDialog from '../../components/CustomDialog/CustomDialog';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Pagination from '../../components/Pagination/Pagination';
import SpecialInput from '../../components/SpecialInput/Input';
import Spinner from '../../components/Spinner/Spinner';
import TableList from '../../components/TableList/TableList';
import { tripsService } from '../../services/trips.service';
import { fetchCouriers } from '../../store/actions/couriers.actions';
import { setToast } from '../../store/actions/toast.actions';
import { deleteTripOffer, fetchTrips } from '../../store/actions/trips.actions';
import { TripStatusFilter } from '../../store/config/enums/tripStatusFilter.enum';
import { Courier, Package, RootState, Trip } from '../../store/config/types';
import OfferTripDialog from './OfferTripDialog';
import TripDetails from './TripDetails';
import TripStopPackages from './TripStopPackages';

const PAGE_LIMIT: number = 12;

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      width: 140,
    },
  }),
);

const mapStateToProps = (state: RootState) => {
  return {
    trips: state.trips,
    couriers: state.couriers,
    loggedIn: state.auth.loggedIn,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  fetchTrips: () => dispatch(fetchTrips()),
  fetchCouriers: () => dispatch(fetchCouriers()),
  deleteTripOffer: (tripOfferId: number) => dispatch(deleteTripOffer(tripOfferId)),
  setToast: (message: string, messageType: string) => dispatch(setToast(message, messageType)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function Trips({ trips, couriers, fetchTrips, fetchCouriers, deleteTripOffer, setToast, loggedIn }: PropsFromRedux) {
  const classes = useStyles();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchText, setSearchText] = useState<string>('');
  const [statusFilter, setStatusFilter] = useState<TripStatusFilter>(TripStatusFilter.ALL);
  const [tripToOffer, setTripToOffer] = useState<Trip | null>(null);
  const [tripDetailsId, setTripDetailsId] = useState<number | null>(null);
  const [stopPackages, setStopPackages] = useState<Package[] | null>(null);
  const [openDialogDelete, setOpenDialogDelete] = useState<boolean>(false);
  const [openDialogReset, setOpenDialogReset] = useState<boolean>(false);

  useEffect(() => {
    if (!trips.loadingTrips && !trips.trips && !trips.tripsErrorMessage) {
      fetchTrips();
    }
  }, [fetchTrips, trips.trips, trips.tripsErrorMessage, trips.loadingTrips, loggedIn]);

  useEffect(() => {
    if (
      !couriers.loadingCouriers &&
      !couriers.couriers &&
      !couriers.couriersFetched &&
      !couriers.couriersErrorMessage
    ) {
      fetchCouriers();
    }
  }, [
    fetchCouriers,
    couriers.loadingCouriers,
    couriers.couriers,
    couriers.couriersFetched,
    couriers.couriersErrorMessage,
  ]);

  const findTripCourier = (trip?: Trip) =>
    couriers.couriers?.find((courier: Courier) => courier.courierId === trip?.courierId);

  const handlePageClick = (page: number) => setCurrentPage(page);

  const handleCreateTrip = () => history.push('/trips/create');

  const searchTextHandler = (text: string) => setSearchText(text);

  const handleDetailTrip = (tripId: number) => setTripDetailsId(tripId);

  const handleClosePackages = () => setStopPackages(null);

  const handleCloseDetails = () => setTripDetailsId(null);

  const handleCloseDialogDelete = () => setOpenDialogDelete(false);
  const handleCloseDialogReset = () => setOpenDialogReset(false);

  // NOTE: I don't see the sense in using redux for this that is only going to be used in dev and qa for
  // NOTE: having the curbhub mock api, and not having to do this from the db.
  const handleDeleteAllTrips = async () => {
    try {
      await tripsService.deleteAllTrips();
      fetchTrips();
      setToast('Trips successfully deleted', 'success');
    } catch (error) {
      setToast('Error deleting trips', 'danger');
    }
    handleCloseDialogDelete();
  };

  const handleResetAllTrips = async () => {
    try {
      await tripsService.resetStateAllTrips();
      fetchTrips();
      setToast('Trips successfully reseted', 'success');
    } catch (error) {
      setToast('Error reseted trips', 'danger');
    }
    handleCloseDialogReset();
  };

  const handleOpenDialogDelete = () => setOpenDialogDelete(true);
  const handleOpenDialogReset = () => setOpenDialogReset(true);

  const legTrip = (firstLeg: boolean) => (
    <Tooltip title={firstLeg ? '1st leg trip' : '2nd leg trip'} placement="top">
      {firstLeg ? <FirstLegTrip height="30px" width="25px" /> : <SecondLegTrip height="30px" width="25px" />}
    </Tooltip>
  );

  const filters = (
    <GridContainer>
      <GridItem xs={12} md={4}>
        <SpecialInput
          element={{
            elementType: 'input',
            elementConfig: { type: 'text', placeholder: 'Search..' },
            value: searchText,
            validation: {},
          }}
          onChange={(e) => searchTextHandler(e)}
        />
      </GridItem>
      <GridItem xs={12} md={4}>
        <FormControl variant="outlined" margin="dense" fullWidth>
          <Select
            placeholder="Status"
            value={statusFilter}
            onChange={(e) => setStatusFilter(Number(e.target.value))}
            variant="outlined"
          >
            <MenuItem value={TripStatusFilter.ALL}>{TripStatusFilter[TripStatusFilter.ALL]}</MenuItem>
            <MenuItem value={TripStatusFilter.NEW}>{TripStatusFilter[TripStatusFilter.NEW]}</MenuItem>
            <MenuItem value={TripStatusFilter.PENDING}>{TripStatusFilter[TripStatusFilter.PENDING]}</MenuItem>
            <MenuItem value={TripStatusFilter.ACCEPTED}>{TripStatusFilter[TripStatusFilter.ACCEPTED]}</MenuItem>
          </Select>
        </FormControl>
      </GridItem>
      <GridItem xs={12} md={4}>
        {!!trips.trips?.length && (
          <Button className={classes.button} color="danger" onClick={handleOpenDialogDelete}>
            Delete All Trips
          </Button>
        )}
        {!!trips.trips?.length && (
          <Button className={classes.button} color="secondary" onClick={handleOpenDialogReset}>
            Reset All Trips
          </Button>
        )}
      </GridItem>
    </GridContainer>
  );

  let tripsContent = null;
  if (trips.trips) {
    let tripsToShow = trips.trips || [];

    if (searchText !== '') {
      tripsToShow = tripsToShow?.filter((trip) => {
        return ['startsAt', 'status'].some((key) => {
          return (trip as any)[key]?.toLowerCase().includes(searchText.toLowerCase());
        });
      });
    }

    if (statusFilter !== TripStatusFilter.ALL) {
      tripsToShow = tripsToShow?.filter((trip) => {
        return trip.status.toLowerCase() === TripStatusFilter[statusFilter].toLowerCase();
      });
    }

    const offerTrip = (trip: Trip) => setTripToOffer(trip);

    const options = tripsToShow.slice((currentPage - 1) * PAGE_LIMIT, currentPage * PAGE_LIMIT).map((trip: Trip) => {
      const tripCourier = findTripCourier(trip);
      const isNewTrip = trip?.status?.toLowerCase() === 'new';
      const date = moment.utc(trip.startsAt);
      const offerCourierId = trip.tripOffers && trip.tripOffers.length > 0 && trip.tripOffers[0].courierId;
      const tripOfferCourier =
        offerCourierId && couriers.couriers?.find((courier: Courier) => courier.courierId === offerCourierId);

      const status = trip.status.toLowerCase().replaceAll('_', ' ');

      return [
        trip.tripId,
        trip.tripId,
        legTrip(trip.firstLeg),
        date.format('MM/DD/YYYY'),
        status.charAt(0).toLocaleUpperCase() + status.slice(1),
        trip.firstStop,
        trip.numberOfPackages,
        trip.numberOfRecipients,
        tripOfferCourier ? `${tripOfferCourier.firstName} ${tripOfferCourier.lastName}` : '-',
        tripCourier ? `${tripCourier?.firstName} ${tripCourier?.lastName}` : '-',
        isNewTrip ? (
          <Button
            className={classes.button}
            key={trip.tripId}
            color={tripOfferCourier ? 'secondary' : 'primary'}
            onClick={() => (tripOfferCourier ? deleteTripOffer(trip!.tripOffers[0].tripOfferId) : offerTrip(trip))}
          >
            {tripOfferCourier ? 'Remove offer' : 'Offer'}
          </Button>
        ) : null,
      ];
    });

    tripsContent = (
      <TableList
        title="Trips"
        head={[
          'Id',
          'Trip number',
          '',
          'Start time',
          'Status',
          'First stop',
          'Number of packages',
          'Number of recipients',
          'Offered to',
          'Courier assigned',
          'Actions',
          '',
        ]}
        filters={filters}
        data={options}
        onShowDetail={handleDetailTrip}
        detail
        pagination={
          <Pagination
            totalRecords={tripsToShow?.length}
            pageLimit={PAGE_LIMIT}
            pageNeighbours={10}
            onPageChanged={handlePageClick}
          />
        }
      />
    );
  } else if (trips.loadingTrips) {
    tripsContent = <Spinner />;
  } else {
    tripsContent = <div>No data to show</div>;
  }

  if (!trips.tripsFetched) {
    return <Spinner />;
  }

  const handleClose = () => {
    setTripToOffer(null);
  };

  return (
    <Fragment>
      {tripsContent}
      <AddButton onClick={handleCreateTrip} />
      {tripDetailsId && (
        <TripDetails
          selectedTripId={tripDetailsId}
          handleClose={handleCloseDetails}
          setStopPackages={setStopPackages}
        />
      )}
      {stopPackages?.length && <TripStopPackages packages={stopPackages} handleClosePackages={handleClosePackages} />}
      <OfferTripDialog open={tripToOffer !== null} trip={tripToOffer} handleClose={handleClose} />
      <CustomDialog
        title={'Confirm'}
        open={openDialogDelete}
        description={'¿Are you sure you want to delete every trip?'}
        onConfirmation={handleDeleteAllTrips}
        handleClose={handleCloseDialogDelete}
        okButtonText={'Yes'}
        cancelButtonText={'Cancel'}
      />
      <CustomDialog
        title={'Confirm'}
        open={openDialogReset}
        description={'¿Are you sure you want to reset all trips?'}
        onConfirmation={handleResetAllTrips}
        handleClose={handleCloseDialogReset}
        okButtonText={'Yes'}
        cancelButtonText={'Cancel'}
      />
    </Fragment>
  );
}

export default connector(Trips);
