import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Formik, Form } from 'formik';
import { RootState } from '../../store/config/types';
import { Shipper } from '../../store/config/types/shippers.types';
import { createShipper, updateShipper, fetchShippers } from '../../store/actions/shippers.actions';
import * as actions from '../../store/actions/toast.actions';
import schemas from '../../utils/schemas';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Button from '../../components/CustomButtons/Button';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import GoogleAutocomplete from '../../components/SpecialInput/GoogleAutocomplete/GoogleAutocomplete';
import InputField from '../../components/SpecialInput/InputField';
import MaskedPhoneInput from '../../components/MaskedPhoneInput/MaskedPhoneInput';
import Spinner from '../../components/Spinner/Spinner';

interface ShipperParams {
  shipperId?: string;
}

interface ShipperValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phoneNumber: string;
  homeAddress: string;
  companyName: string;
}

const mapStateToProps = (state: RootState) => ({
  shippers: state.shippers,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  onCreateShipper: (shipper: Shipper) => dispatch(createShipper(shipper)),
  onUpdateShipper: (shipper: Shipper) => dispatch(updateShipper(shipper)),
  fetchShippers: () => dispatch(fetchShippers()),
  setToast: (message: string, messageType: string) => dispatch(actions.setToast(message, messageType)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const ShippersForm = ({ onCreateShipper, onUpdateShipper, fetchShippers, setToast, shippers }: PropsFromRedux) => {
  const history = useHistory();
  const { shipperId } = useParams<ShipperParams>();
  const editMode: boolean = Boolean(shipperId);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [selectedShipper, setSelectedShipper] = useState<Shipper | null>(null);
  const [address, setAddress] = useState<string>('');

  useEffect(() => {
    if (
      !shippers.loadingShippers &&
      !shippers.shippers &&
      !shippers.shippersFetched &&
      shippers.shippersErrorMessage === null
    ) {
      fetchShippers();
    }
  }, [
    fetchShippers,
    shippers.loadingShippers,
    shippers.shippers,
    shippers.shippersFetched,
    shippers.shippersErrorMessage,
  ]);

  useEffect(() => {
    if (editMode && !selectedShipper && shippers.shippers && shipperId) {
      const editedShipper = shippers.shippers.filter((s: Shipper) => s.shipperId === Number(shipperId))[0];
      setSelectedShipper(editedShipper);
      setAddress(editedShipper.homeAddress!);
    }
  }, [editMode, selectedShipper, shippers.shippers, shipperId, setSelectedShipper]);

  useEffect(() => {
    if (submitting) {
      if (shippers.createShipperSuccess || shippers.updateShipperSuccess) {
        setSubmitting(false);
        setToast(`Shipper successfully ${shippers.createShipperSuccess ? 'created' : 'updated'}`, 'success');
        history.push('/shippers');
      } else if (shippers.createShipperErrorMessage || shippers.updateShipperErrorMessage) {
        setSubmitting(false);
        setToast(
          `An error has ocurred while ${shippers.createShipperErrorMessage ? 'creating' : 'updating'} shipper`,
          'danger',
        );
      }
    }
  }, [
    submitting,
    shippers.createShipperSuccess,
    shippers.updateShipperSuccess,
    shippers.createShipperErrorMessage,
    shippers.updateShipperErrorMessage,
    history,
    setSubmitting,
    setToast,
  ]);

  const handleGoBack = () => history.push('/shippers');

  const submitForm = (values: ShipperValues) => {
    const shipperRequest: Shipper = {
      ...values,
    };

    setSubmitting(true);

    if (editMode) {
      shipperRequest.shipperId = selectedShipper?.shipperId;
      onUpdateShipper(shipperRequest);
    } else {
      onCreateShipper(shipperRequest);
    }
  };

  if (editMode && !selectedShipper) {
    return <Spinner />;
  }

  return (
    <div className="shipper-form">
      <Formik
        initialValues={{
          firstName: selectedShipper ? selectedShipper.firstName! : '',
          lastName: selectedShipper ? selectedShipper.lastName! : '',
          email: selectedShipper ? selectedShipper.email! : '',
          password: selectedShipper ? selectedShipper.password! : '',
          phoneNumber: selectedShipper ? selectedShipper.phoneNumber! : '',
          homeAddress: selectedShipper ? selectedShipper.homeAddress! : '',
          companyName: selectedShipper ? selectedShipper.companyName! : '',
          shipperId: selectedShipper ? selectedShipper.shipperId : shipperId,
        }}
        validationSchema={schemas.ShipperSchema}
        onSubmit={submitForm}
      >
        {({ setFieldValue }) => (
          <Form>
            <Card profile>
              <CardHeader color="primary" className="card-header">
                {editMode && selectedShipper ? (
                  <h4>{`${selectedShipper.firstName} ${selectedShipper.lastName}`}</h4>
                ) : (
                  <h4>New shipper</h4>
                )}
              </CardHeader>
              <CardBody profile>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={6}>
                    <InputField placeholder="First name" name="firstName" type="text" />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <InputField placeholder="Last name" name="lastName" type="text" />
                  </GridItem>
                </GridContainer>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={6}>
                    <InputField placeholder="Email" name="email" type="text" />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <InputField placeholder="Company name" name="companyName" type="text" />
                  </GridItem>
                </GridContainer>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={6}>
                    <MaskedPhoneInput placeholder="Phone number" name="phoneNumber" setfieldvalue={setFieldValue} />
                  </GridItem>
                  <GoogleAutocomplete
                    address={address}
                    setAddress={setAddress}
                    setFieldValue={setFieldValue}
                    usesAllGoogleProps={true}
                    inputName={'homeAddress'}
                    placeholderName={'Home address'}
                    inputSize={6}
                  />
                </GridContainer>
                {!editMode && (
                  <GridItem xs={12} sm={6}>
                    <InputField placeholder="Password" name="password" type="password" />
                  </GridItem>
                )}
                <GridContainer>
                  <GridItem xs={12} sm={12}>
                    <div className="btn-group">
                      <Button type="submit" className="submit-button" color="primary">
                        {editMode ? 'UPDATE' : 'CREATE'} SHIPPER
                      </Button>
                      <Button onClick={handleGoBack} className="cancel-button" color="secondary">
                        Cancel
                      </Button>
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default connector(ShippersForm);
