import classNames from 'classnames';
import { withStyles, AppBar, Toolbar, Hidden } from '@material-ui/core';
import HeaderLinks from './HeaderLinks/HeaderLinks';
import headerStyle from '../../assets/jss/headerStyle.jsx';

const Header = ({ classes, color, ...props }) => {
  const appBarClasses = classNames({ [' ' + classes[color]]: color });

  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex} />
        <Hidden smDown implementation="css">
          <HeaderLinks onLogoutClick={props.handleLogout} />
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(headerStyle)(Header);
