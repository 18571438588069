import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../store/config/types';
import { ExtendedDeliveryOrder, PackageModel } from '../../store/config/types/deliveryOrders.types';
import { createPackagesList } from '../../utils/deliveryOrders.helpers';
import TableList from '../../components/TableList/TableList';

interface PackagesTableProps {
  // eslint-disable-next-line no-unused-vars
  selectedDeliveryOrder: ExtendedDeliveryOrder | null;
}

const mapStateToProps = (state: RootState) => ({
  customers: state.customers,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type PropsType = PackagesTableProps & PropsFromRedux;

function PackagesTable({ selectedDeliveryOrder, customers }: PropsType) {
  const packagesList: PackageModel[] = createPackagesList(selectedDeliveryOrder!);

  return (
    <div className="packages-table">
      <TableList
        head={['Id', 'Name', 'Address', 'Number of packages', 'Size of packages', 'Status', '']}
        data={packagesList.map((packageObject: PackageModel) => {
          const customer = customers.customers?.find((c) => c.customerId === packageObject.customerId);

          return [
            packageObject.packageId,
            customer?.contactPerson || `${customer?.firstName} ${customer?.lastName}` || packageObject.firstName || '-',
            customer?.location?.streetAddress || packageObject.streetAddress || '-',
            packageObject.packagesAmount,
            packageObject.packagesSize !== 'Custom' ? packageObject.packagesSize : packageObject.customSize,
            packageObject.packagesStatus,
          ];
        })}
      />
    </div>
  );
}

export default connector(PackagesTable);
