import { Dispatch } from 'redux';
import { transshipmentsService } from '../../services/transshipments.service';
import { Transshipment } from '../config/types';
import {
  FETCHING_TRANSSHIPMENTS,
  FETCH_TRANSSHIPMENTS_FAILED,
  FETCH_TRANSSHIPMENTS_SUCCESS,
  FetchTransshipmentTypes,
  SetTransshipmentType,
  CreateTransshipmentTypes,
  UpdateTransshipmentTypes,
  CREATING_TRANSSHIPMENT,
  CREATE_TRANSSHIPMENT_SUCCESS,
  CREATE_TRANSSHIPMENT_FAILED,
  UPDATING_TRANSSHIPMENT,
  UPDATE_TRANSSHIPMENT_SUCCESS,
  UPDATE_TRANSSHIPMENT_FAILED,
  SET_TRANSSHIPMENT,
  UNSET_TRANSSHIPMENT,
} from '../config/ActionTypes';

// #region Fetch Transshipments

export const fetchTransshipments = () => {
  return (dispatch: Dispatch<FetchTransshipmentTypes>) => {
    dispatch(fetchingTransshipments());

    return transshipmentsService.fetchTransshipments().then(
      (response) => {
        dispatch(fetchTransshipmentsSuccess(response!));
      },
      (error) => {
        dispatch(fetchTransshipmentsFailed(error));
      },
    );
  };
};

export const fetchingTransshipments = (): FetchTransshipmentTypes => ({
  type: FETCHING_TRANSSHIPMENTS,
});

export const fetchTransshipmentsSuccess = (transshipments: Transshipment[]): FetchTransshipmentTypes => {
  return {
    type: FETCH_TRANSSHIPMENTS_SUCCESS,
    transshipments,
  };
};

export const fetchTransshipmentsFailed = (error: string): FetchTransshipmentTypes => ({
  type: FETCH_TRANSSHIPMENTS_FAILED,
  error,
});

// #endregion Fetch Transshipments

// #region Set Transshipment

export const setTransshipment = (transshipmentId?: string) => {
  return (dispatch: Dispatch<SetTransshipmentType>) => {
    if (transshipmentId) {
      dispatch(setTransshipmentInState(transshipmentId));
    } else {
      dispatch(unsetTransshipmentInState());
    }
  };
};

export const unsetTransshipmentInState = (): SetTransshipmentType => ({
  type: UNSET_TRANSSHIPMENT,
});

export const setTransshipmentInState = (transshipmentId: string): SetTransshipmentType => ({
  type: SET_TRANSSHIPMENT,
  transshipmentId,
});

// #endregion Set Transshipment

// #region Create Transshipment

export const createTransshipment = (createTransshipmentRequest: Transshipment) => {
  return (dispatch: Dispatch<CreateTransshipmentTypes>) => {
    dispatch(creatingTransshipment());

    return transshipmentsService.createTransshipment(createTransshipmentRequest).then(
      (response) => {
        dispatch(createTransshipmentSuccess(response!));
      },
      (error) => {
        dispatch(createTransshipmentFailed(error));
      },
    );
  };
};

export const creatingTransshipment = (): CreateTransshipmentTypes => ({
  type: CREATING_TRANSSHIPMENT,
});

export const createTransshipmentSuccess = (transshipment: Transshipment): CreateTransshipmentTypes => ({
  type: CREATE_TRANSSHIPMENT_SUCCESS,
  transshipment,
});

export const createTransshipmentFailed = (error: string): CreateTransshipmentTypes => ({
  type: CREATE_TRANSSHIPMENT_FAILED,
  error,
});

// #endregion Create Transshipment

// #region Update Transshipment

export const updateTransshipment = (transshipmentId: number, updateTransshipmentRequest: Transshipment) => {
  return (dispatch: Dispatch<UpdateTransshipmentTypes>) => {
    dispatch(updatingTransshipment());

    return transshipmentsService.updateTransshipment(transshipmentId, updateTransshipmentRequest).then(
      (response) => {
        dispatch(updateTransshipmentSuccess(response!));
      },
      (error) => {
        dispatch(updateTransshipmentFailed(error));
      },
    );
  };
};

export const updatingTransshipment = (): UpdateTransshipmentTypes => ({
  type: UPDATING_TRANSSHIPMENT,
});

export const updateTransshipmentSuccess = (transshipment: Transshipment): UpdateTransshipmentTypes => ({
  type: UPDATE_TRANSSHIPMENT_SUCCESS,
  transshipment,
});

export const updateTransshipmentFailed = (error: string): UpdateTransshipmentTypes => ({
  type: UPDATE_TRANSSHIPMENT_FAILED,
  error,
});

// #endregion Update Transshipment
