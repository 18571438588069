import { Dispatch } from 'redux';
import { warehousesService } from '../../services/warehouses.service';
import { Warehouse } from '../config/types/warehouses.types';
import {
  FETCHING_WAREHOUSES,
  FETCH_WAREHOUSES_SUCCESS,
  FETCH_WAREHOUSES_FAILED,
  FetchWarehousesTypes,
  CREATING_WAREHOUSE,
  CREATE_WAREHOUSE_SUCCESS,
  CREATE_WAREHOUSE_FAILED,
  UPDATING_WAREHOUSE,
  UPDATE_WAREHOUSE_SUCCESS,
  UPDATE_WAREHOUSE_FAILED,
  DELETING_WAREHOUSE,
  DELETE_WAREHOUSE_SUCCESS,
  DELETE_WAREHOUSE_FAILED,
  CreateWarehouseTypes,
  UpdateWarehouseTypes,
  DeleteWarehouseTypes,
} from '../config/ActionTypes';

// #region Fetch Warehouses

export const fetchWarehouses = () => {
  return (dispatch: Dispatch<FetchWarehousesTypes>) => {
    dispatch(fetchingWarehouses());

    return warehousesService.fetchWarehouses().then(
      (response) => {
        dispatch(fetchWarehousesSuccess(response!));
      },
      (error) => {
        dispatch(fetchWarehousesFailed(error));
      },
    );
  };
};

export const fetchingWarehouses = (): FetchWarehousesTypes => ({
  type: FETCHING_WAREHOUSES,
});

export const fetchWarehousesSuccess = (warehouses: Warehouse[]): FetchWarehousesTypes => ({
  type: FETCH_WAREHOUSES_SUCCESS,
  warehouses,
});

export const fetchWarehousesFailed = (error: string): FetchWarehousesTypes => ({
  type: FETCH_WAREHOUSES_FAILED,
  error,
});

// #endregion Fetch Warehouses

// #region Create Warehouse

export const createWarehouse = (createWarehouseRequest: Warehouse) => {
  return (dispatch: Dispatch<CreateWarehouseTypes>) => {
    dispatch(creatingWarehouse());

    return warehousesService.createWarehouse(createWarehouseRequest).then(
      (response) => {
        dispatch(createWarehouseSuccess(response!));
      },
      (error) => {
        dispatch(createWarehouseFailed(error));
      },
    );
  };
};

export const creatingWarehouse = (): CreateWarehouseTypes => ({
  type: CREATING_WAREHOUSE,
});

export const createWarehouseSuccess = (warehouse: Warehouse): CreateWarehouseTypes => ({
  type: CREATE_WAREHOUSE_SUCCESS,
  warehouse,
});

export const createWarehouseFailed = (error: string): CreateWarehouseTypes => ({
  type: CREATE_WAREHOUSE_FAILED,
  error,
});

// #endregion Create Warehouse

// #region Update Warehouse

export const updateWarehouse = (warehouseId: number, updateWarehouseRequest: Warehouse) => {
  return (dispatch: Dispatch<UpdateWarehouseTypes>) => {
    dispatch(updatingWarehouse());

    return warehousesService.updateWarehouse(warehouseId, updateWarehouseRequest).then(
      () => {
        dispatch(updateWarehouseSuccess(updateWarehouseRequest));
      },
      (error) => {
        dispatch(updateWarehouseFailed(error));
      },
    );
  };
};

export const updatingWarehouse = (): UpdateWarehouseTypes => ({
  type: UPDATING_WAREHOUSE,
});

export const updateWarehouseSuccess = (warehouse: Warehouse): UpdateWarehouseTypes => ({
  type: UPDATE_WAREHOUSE_SUCCESS,
  warehouse,
});

export const updateWarehouseFailed = (error: string): UpdateWarehouseTypes => ({
  type: UPDATE_WAREHOUSE_FAILED,
  error,
});

// #endregion Update Warehouse

// #region Delete Warehouse

export const deleteWarehouse = (warehouseId: number) => {
  return (dispatch: Dispatch<DeleteWarehouseTypes>) => {
    dispatch(deletingWarehouse());

    return warehousesService.deleteWarehouse(warehouseId).then(
      () => {
        dispatch(deleteWarehouseSuccess(warehouseId));
      },
      (error) => {
        dispatch(deleteWarehouseFailed(error));
      },
    );
  };
};

export const deletingWarehouse = (): DeleteWarehouseTypes => ({
  type: DELETING_WAREHOUSE,
});

export const deleteWarehouseSuccess = (warehouseId: number): DeleteWarehouseTypes => ({
  type: DELETE_WAREHOUSE_SUCCESS,
  warehouseId,
});

export const deleteWarehouseFailed = (error: string): DeleteWarehouseTypes => ({
  type: DELETE_WAREHOUSE_FAILED,
  error,
});

// #endregion Delete Warehouse
