import { LoggedAccount, Courier, Vehicle, Transshipment, DeliveryOrder, Customer, Trip, Stop } from './types';
import { Warehouse } from './types/warehouses.types';
import { Shipper } from './types/shippers.types';

// #region Login

export const LOGGING_IN = 'LOGGING_IN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const CLEAR_LOGIN_ERROR = 'CLEAR_LOGIN_ERROR';

export interface LoggingInAction {
  type: typeof LOGGING_IN;
}

export interface LoginSuccessAction {
  type: typeof LOGIN_SUCCESS;
  token: string;
  account: LoggedAccount | undefined;
}

export interface LoginErrorAction {
  type: typeof LOGIN_ERROR;
  error: string;
}

export interface ClearLoginErrorAction {
  type: typeof CLEAR_LOGIN_ERROR;
}

export type LoginActionTypes = LoggingInAction | LoginSuccessAction | LoginErrorAction | ClearLoginErrorAction;

// #endregion Login

// #region Logout

export const LOGGING_OUT = 'LOGGING_OUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';

export interface LoggingOutAction {
  type: typeof LOGGING_OUT;
}

export interface LogoutSuccess {
  type: typeof LOGOUT_SUCCESS;
}

export interface LogoutFailed {
  type: typeof LOGOUT_FAILED;
  error: string;
}

export type LogoutActionTypes = LoggingOutAction | LogoutSuccess | LogoutFailed;

// #endregion Logout

// #region Account

export const GETTING_ACCOUNT = 'GETTING_ACCOUNT';
export const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS';
export const GET_ACCOUNT_ERROR = 'GET_ACCOUNT_ERROR';

export interface GettingAccount {
  type: typeof GETTING_ACCOUNT;
}

export interface GetAccountSuccess {
  type: typeof GET_ACCOUNT_SUCCESS;
  account: LoggedAccount;
}

export interface GetAccountError {
  type: typeof GET_ACCOUNT_ERROR;
  error: string;
}

export type GetAccountActionTypes = GettingAccount | GetAccountSuccess | GetAccountError;

export type AuthDataTypes = LoginActionTypes | LogoutActionTypes | GetAccountActionTypes;

// #endregion Account

// #region Toast

export const SET_TOAST = 'SET_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';

export interface SetToastAction {
  type: typeof SET_TOAST;
  message: string;
  messageType: string;
}

export interface RemoveToastAction {
  type: typeof REMOVE_TOAST;
}

export type ToastActionTypes = SetToastAction | RemoveToastAction;

// #endregion Toast

// #region Couriers

export const FETCHING_COURIERS = 'FETCHING_COURIERS';
export const FETCH_COURIERS_SUCCESS = 'FETCH_COURIERS_SUCCESS';
export const FETCH_COURIERS_FAILED = 'FETCH_COURIERS_FAILED';

export interface FetchingCouriersAction {
  type: typeof FETCHING_COURIERS;
}

export interface FetchCouriersFailedAction {
  type: typeof FETCH_COURIERS_FAILED;
  error: string;
}

export interface FetchCouriersSuccessAction {
  type: typeof FETCH_COURIERS_SUCCESS;
  couriers: Courier[];
}

export type FetchCouriersTypes = FetchingCouriersAction | FetchCouriersSuccessAction | FetchCouriersFailedAction;

export const SET_COURIER = 'SET_COURIER';
export const UNSET_COURIER = 'UNSET_COURIER';

export interface SetCourierAction {
  type: typeof SET_COURIER;
  courierId: string;
}

export interface UnsetCourierAction {
  type: typeof UNSET_COURIER;
}

export type SetCourierType = SetCourierAction | UnsetCourierAction;

export const CREATING_COURIER = 'CREATING_COURIER';
export const CREATE_COURIER_SUCCESS = 'CREATE_COURIER_SUCCESS';
export const CREATE_COURIER_FAILED = 'CREATE_COURIER_FAILED';

export interface CreatingCourierAction {
  type: typeof CREATING_COURIER;
}

export interface CreateCourierSuccessAction {
  type: typeof CREATE_COURIER_SUCCESS;
  courier: Courier;
}

export interface CreateCourierFailedAction {
  type: typeof CREATE_COURIER_FAILED;
  error: string;
}

export type CreateCourierTypes = CreatingCourierAction | CreateCourierSuccessAction | CreateCourierFailedAction;

export const UPDATING_COURIER = 'UPDATING_COURIER';
export const UPDATE_COURIER_SUCCESS = 'UPDATE_COURIER_SUCCESS';
export const UPDATE_COURIER_FAILED = 'UPDATE_COURIER_FAILED';

export interface UpdatingCourierAction {
  type: typeof UPDATING_COURIER;
}

export interface UpdateCourierSuccessAction {
  type: typeof UPDATE_COURIER_SUCCESS;
  courier: Courier;
}

export interface UpdateCourierFailedAction {
  type: typeof UPDATE_COURIER_FAILED;
  error: string;
}

export type UpdateCourierTypes = UpdatingCourierAction | UpdateCourierSuccessAction | UpdateCourierFailedAction;

export const DELETING_COURIER = 'DELETING_COURIER';
export const DELETE_COURIER_SUCCESS = 'DELETE_COURIER_SUCCESS';
export const DELETE_COURIER_FAILED = 'DELETE_COURIER_FAILED';

export interface DeletingCourierAction {
  type: typeof DELETING_COURIER;
}

export interface DeleteCourierSuccessAction {
  type: typeof DELETE_COURIER_SUCCESS;
  courierId: number;
}

export interface DeleteCourierFailedAction {
  type: typeof DELETE_COURIER_FAILED;
  error: string;
}

export type DeleteCourierTypes = DeletingCourierAction | DeleteCourierSuccessAction | DeleteCourierFailedAction;

export const ADDING_VEHICLE = 'ADDING_VEHICLE';
export const ADD_VEHICLE_SUCCESS = 'ADD_VEHICLE_SUCCESS';
export const ADD_VEHICLE_FAILED = 'ADD_VEHICLE_FAILED';

export interface AddingVehicleAction {
  type: typeof ADDING_VEHICLE;
}

export interface AddVehicleSuccessAction {
  type: typeof ADD_VEHICLE_SUCCESS;
  vehicle: Vehicle;
}

export interface AddVehicleFailureAction {
  type: typeof ADD_VEHICLE_FAILED;
  error: string;
}

export type AddVehiclesTypes = AddingVehicleAction | AddVehicleSuccessAction | AddVehicleFailureAction;

export const UPDATING_VEHICLE = 'UPDATING_VEHICLE';
export const UPDATE_VEHICLE_SUCCESS = 'UPDATE_VEHICLE_SUCCESS';
export const UPDATE_VEHICLE_FAILED = 'UPDATE_VEHICLE_FAILED';

export interface UpdatingVehicleAction {
  type: typeof UPDATING_VEHICLE;
}

export interface UpdateVehicleSuccessAction {
  type: typeof UPDATE_VEHICLE_SUCCESS;
  vehicle: Vehicle;
}

export interface UpdateVehicleFailureAction {
  type: typeof UPDATE_VEHICLE_FAILED;
  error: string;
}

export type UpdateVehiclesTypes = UpdatingVehicleAction | UpdateVehicleSuccessAction | UpdateVehicleFailureAction;

export const DELETING_VEHICLE = 'DELETING_VEHICLE';
export const DELETE_VEHICLE_SUCCESS = 'DELETE_VEHICLE_SUCCESS';
export const DELETE_VEHICLE_FAILED = 'DELETE_VEHICLE_FAILED';

export interface DeletingVehicleAction {
  type: typeof DELETING_VEHICLE;
}

export interface DeleteVehicleSuccessAction {
  type: typeof DELETE_VEHICLE_SUCCESS;
  vehicleId: string;
}

export interface DeleteVehicleFailureAction {
  type: typeof DELETE_VEHICLE_FAILED;
  error: string;
}

export type DeleteVehiclesTypes = DeletingVehicleAction | DeleteVehicleSuccessAction | DeleteVehicleFailureAction;

export const CLEAR_COURIERS_STATE = 'CLEAR_COURIERS_STATE';

export interface ClearCouriersStateType {
  type: typeof CLEAR_COURIERS_STATE;
}

export type CouriersActionTypes =
  | FetchCouriersTypes
  | CreateCourierTypes
  | UpdateCourierTypes
  | DeleteCourierTypes
  | AddVehiclesTypes
  | UpdateVehiclesTypes
  | DeleteVehiclesTypes
  | SetCourierType
  | ClearCouriersStateType;

// #endregion Couriers

// #region Warehouses

export const FETCHING_WAREHOUSES = 'FETCHING_WAREHOUSES';
export const FETCH_WAREHOUSES_SUCCESS = 'FETCH_WAREHOUSES_SUCCESS';
export const FETCH_WAREHOUSES_FAILED = 'FETCH_WAREHOUSES_FAILED';

export interface FetchingWarehousesAction {
  type: typeof FETCHING_WAREHOUSES;
}

export interface FetchWarehousesFailedAction {
  type: typeof FETCH_WAREHOUSES_FAILED;
  error: string;
}

export interface FetchWarehousesSuccessAction {
  type: typeof FETCH_WAREHOUSES_SUCCESS;
  warehouses: Warehouse[];
}

export type FetchWarehousesTypes =
  | FetchingWarehousesAction
  | FetchWarehousesSuccessAction
  | FetchWarehousesFailedAction;

export const CREATING_WAREHOUSE = 'CREATING_WAREHOUSE';
export const CREATE_WAREHOUSE_SUCCESS = 'CREATE_WAREHOUSE_SUCCESS';
export const CREATE_WAREHOUSE_FAILED = 'CREATE_WAREHOUSE_FAILED';

export interface CreatingWarehouseAction {
  type: typeof CREATING_WAREHOUSE;
}

export interface CreateWarehouseSuccessAction {
  type: typeof CREATE_WAREHOUSE_SUCCESS;
  warehouse: Warehouse;
}

export interface CreateWarehouseFailedAction {
  type: typeof CREATE_WAREHOUSE_FAILED;
  error: string;
}

export type CreateWarehouseTypes = CreatingWarehouseAction | CreateWarehouseSuccessAction | CreateWarehouseFailedAction;

export const UPDATING_WAREHOUSE = 'UPDATING_WAREHOUSE';
export const UPDATE_WAREHOUSE_SUCCESS = 'UPDATE_WAREHOUSE_SUCCESS';
export const UPDATE_WAREHOUSE_FAILED = 'UPDATE_WAREHOUSE_FAILED';

export interface UpdatingWarehouseAction {
  type: typeof UPDATING_WAREHOUSE;
}

export interface UpdateWarehouseSuccessAction {
  type: typeof UPDATE_WAREHOUSE_SUCCESS;
  warehouse: Warehouse;
}

export interface UpdateWarehouseFailedAction {
  type: typeof UPDATE_WAREHOUSE_FAILED;
  error: string;
}

export type UpdateWarehouseTypes = UpdatingWarehouseAction | UpdateWarehouseSuccessAction | UpdateWarehouseFailedAction;

export const DELETING_WAREHOUSE = 'DELETING_WAREHOUSE';
export const DELETE_WAREHOUSE_SUCCESS = 'DELETE_WAREHOUSE_SUCCESS';
export const DELETE_WAREHOUSE_FAILED = 'DELETE_WAREHOUSE_FAILED';

export interface DeletingWarehouseAction {
  type: typeof DELETING_WAREHOUSE;
}

export interface DeleteWarehouseSuccessAction {
  type: typeof DELETE_WAREHOUSE_SUCCESS;
  warehouseId: number;
}

export interface DeleteWarehouseFailedAction {
  type: typeof DELETE_WAREHOUSE_FAILED;
  error: string;
}

export type DeleteWarehouseTypes = DeletingWarehouseAction | DeleteWarehouseSuccessAction | DeleteWarehouseFailedAction;

export type WarehousesActionTypes =
  | FetchWarehousesTypes
  | CreateWarehouseTypes
  | UpdateWarehouseTypes
  | DeleteWarehouseTypes;

// #endregion Warehouses

// #region Transshipments

export const FETCHING_TRANSSHIPMENTS = 'FETCHING_TRANSSHIPMENTS';
export const FETCH_TRANSSHIPMENTS_SUCCESS = 'FETCH_TRANSSHIPMENTS_SUCCESS';
export const FETCH_TRANSSHIPMENTS_FAILED = 'FETCH_TRANSSHIPMENTS_FAILED';

export interface FetchingTransshipmentsAction {
  type: typeof FETCHING_TRANSSHIPMENTS;
}

export interface FetchTransshipmentsFailedAction {
  type: typeof FETCH_TRANSSHIPMENTS_FAILED;
  error: string;
}

export interface FetchTransshipmentsSuccessAction {
  type: typeof FETCH_TRANSSHIPMENTS_SUCCESS;
  transshipments: Transshipment[];
}

export type FetchTransshipmentTypes =
  | FetchingTransshipmentsAction
  | FetchTransshipmentsSuccessAction
  | FetchTransshipmentsFailedAction;

export const SET_TRANSSHIPMENT = 'SET_TRANSSHIPMENT';
export const UNSET_TRANSSHIPMENT = 'UNSET_TRANSSHIPMENT';

export interface SetTransshipmentsAction {
  type: typeof SET_TRANSSHIPMENT;
  transshipmentId: string;
}

export interface UnsetTransshipmentsAction {
  type: typeof UNSET_TRANSSHIPMENT;
}

export type SetTransshipmentType = SetTransshipmentsAction | UnsetTransshipmentsAction;

export const CREATING_TRANSSHIPMENT = 'CREATING_TRANSSHIPMENT';
export const CREATE_TRANSSHIPMENT_SUCCESS = 'CREATE_TRANSSHIPMENT_SUCCESS';
export const CREATE_TRANSSHIPMENT_FAILED = 'CREATE_TRANSSHIPMENT_FAILED';

export interface CreatingTransshipmentAction {
  type: typeof CREATING_TRANSSHIPMENT;
}

export interface CreateTransshipmentSuccessAction {
  type: typeof CREATE_TRANSSHIPMENT_SUCCESS;
  transshipment: Transshipment;
}

export interface CreateTransshipmentFailedAction {
  type: typeof CREATE_TRANSSHIPMENT_FAILED;
  error: string;
}

export type CreateTransshipmentTypes =
  | CreatingTransshipmentAction
  | CreateTransshipmentSuccessAction
  | CreateTransshipmentFailedAction;

export const UPDATING_TRANSSHIPMENT = 'UPDATING_TRANSSHIPMENT';
export const UPDATE_TRANSSHIPMENT_SUCCESS = 'UPDATE_TRANSSHIPMENT_SUCCESS';
export const UPDATE_TRANSSHIPMENT_FAILED = 'UPDATE_TRANSSHIPMENT_FAILED';

export interface UpdatingTransshipmentAction {
  type: typeof UPDATING_TRANSSHIPMENT;
}

export interface UpdateTransshipmentSuccessAction {
  type: typeof UPDATE_TRANSSHIPMENT_SUCCESS;
  transshipment: Transshipment;
}

export interface UpdateTransshipmentFailedAction {
  type: typeof UPDATE_TRANSSHIPMENT_FAILED;
  error: string;
}

export type UpdateTransshipmentTypes =
  | UpdatingTransshipmentAction
  | UpdateTransshipmentSuccessAction
  | UpdateTransshipmentFailedAction;

export type TransshipmentsActionTypes =
  | FetchTransshipmentTypes
  | SetTransshipmentType
  | CreateTransshipmentTypes
  | UpdateTransshipmentTypes;

// #endregion Transshipments

// #region Shippers

export const FETCHING_SHIPPERS = 'FETCHING_SHIPPERS';
export const FETCH_SHIPPERS_SUCCESS = 'FETCH_SHIPPERS_SUCCESS';
export const FETCH_SHIPPERS_FAILED = 'FETCH_SHIPPERS_FAILED';

export interface FetchingShippersAction {
  type: typeof FETCHING_SHIPPERS;
}

export interface FetchShippersFailedAction {
  type: typeof FETCH_SHIPPERS_FAILED;
  error: string;
}

export interface FetchShippersSuccessAction {
  type: typeof FETCH_SHIPPERS_SUCCESS;
  shippers: Shipper[];
}

export type FetchShippersTypes = FetchingShippersAction | FetchShippersFailedAction | FetchShippersSuccessAction;

export const SET_SHIPPER = 'SET_SHIPPER';
export const UNSET_SHIPPER = 'UNSET_SHIPPER';

export interface setShipperInState {
  type: typeof SET_SHIPPER;
  shipperId: number;
}

export interface unsetShipperInState {
  type: typeof UNSET_SHIPPER;
}

export type SetShipperTypes = setShipperInState | unsetShipperInState;

export const CREATING_SHIPPER = 'CREATING_SHIPPER';
export const CREATE_SHIPPER_SUCCESS = 'CREATE_SHIPPER_SUCCESS';
export const CREATE_SHIPPER_FAILED = 'CREATE_SHIPPER_FAILED';

export interface CreatingShipperAction {
  type: typeof CREATING_SHIPPER;
}

export interface CreateShipperSuccessAction {
  type: typeof CREATE_SHIPPER_SUCCESS;
  shipper: Shipper;
}

export interface CreateShipperFailedAction {
  type: typeof CREATE_SHIPPER_FAILED;
  error: string;
}

export type CreateShipperTypes = CreatingShipperAction | CreateShipperSuccessAction | CreateShipperFailedAction;

export const UPDATING_SHIPPER = 'UPDATING_SHIPPER';
export const UPDATE_SHIPPER_SUCCESS = 'UPDATE_SHIPPER_SUCCESS';
export const UPDATE_SHIPPER_FAILED = 'UPDATE_SHIPPER_FAILED';

export interface UpdatingShipperAction {
  type: typeof UPDATING_SHIPPER;
}

export interface UpdateShipperSuccessAction {
  type: typeof UPDATE_SHIPPER_SUCCESS;
  shipper: Shipper;
}

export interface UpdateShipperFailedAction {
  type: typeof UPDATE_SHIPPER_FAILED;
  error: string;
}

export type UpdateShipperTypes = UpdatingShipperAction | UpdateShipperSuccessAction | UpdateShipperFailedAction;

export const DELETING_SHIPPER = 'DELETING_SHIPPER';
export const DELETE_SHIPPER_SUCCESS = 'DELETE_SHIPPER_SUCCESS';
export const DELETE_SHIPPER_FAILED = 'DELETE_SHIPPER_FAILED';

export interface DeletingShipperAction {
  type: typeof DELETING_SHIPPER;
}

export interface DeleteShipperSuccessAction {
  type: typeof DELETE_SHIPPER_SUCCESS;
  shipperId: number;
}

export interface DeleteShipperFailedAction {
  type: typeof DELETE_SHIPPER_FAILED;
  error: string;
}

export type DeleteShipperTypes = DeletingShipperAction | DeleteShipperSuccessAction | DeleteShipperFailedAction;

export type ShippersActionTypes =
  | FetchShippersTypes
  | SetShipperTypes
  | CreateShipperTypes
  | UpdateShipperTypes
  | DeleteShipperTypes;

// #endregion Shippers

// #region Delivery orders

export const FETCHING_DELIVERY_ORDERS = 'FETCHING_DELIVERY_ORDERS';
export const FETCH_DELIVERY_ORDERS_SUCCESS = 'FETCH_DELIVERY_ORDERS_SUCCESS';
export const FETCH_DELIVERY_ORDERS_FAILED = 'FETCH_DELIVERY_ORDERS_FAILED';

export interface FetchingDeliveryOrdersAction {
  type: typeof FETCHING_DELIVERY_ORDERS;
}

export interface FetchDeliveryOrdersSuccessAction {
  type: typeof FETCH_DELIVERY_ORDERS_SUCCESS;
  deliveryOrders: DeliveryOrder[];
}

export interface FetchDeliveryOrdersFailedAction {
  type: typeof FETCH_DELIVERY_ORDERS_FAILED;
  error: string;
}

export type FetchDeliveryOrdersTypes =
  | FetchingDeliveryOrdersAction
  | FetchDeliveryOrdersSuccessAction
  | FetchDeliveryOrdersFailedAction;

export type DeliveryOrdersActionTypes = FetchDeliveryOrdersTypes;

// #endregion Delivery Orders

// #region Customers

export const FETCHING_CUSTOMERS = 'FETCHING_CUSTOMERS';
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_FAILED = 'FETCH_CUSTOMERS_FAILED';

export interface FetchingCustomersAction {
  type: typeof FETCHING_CUSTOMERS;
  shipperId: number;
}

export interface FetchCustomersFailedAction {
  type: typeof FETCH_CUSTOMERS_FAILED;
  error: string;
}

export interface FetchCustomersSuccessAction {
  type: typeof FETCH_CUSTOMERS_SUCCESS;
  customers: Customer[];
}

export type FetchCustomersTypes = FetchingCustomersAction | FetchCustomersFailedAction | FetchCustomersSuccessAction;

export type CustomersActionTypes = FetchCustomersTypes;

// #endregion Customers

// #region Trips

export const FETCHING_TRIPS = 'FETCHING_TRIPS';
export const FETCH_TRIPS_SUCCESS = 'FETCH_TRIPS_SUCCESS';
export const FETCH_TRIPS_FAILED = 'FETCH_TRIPS_FAILED';

export const FETCHING_TRIP_STOPS = 'FETCHING_TRIP_STOPS';
export const FETCH_TRIP_STOPS_SUCCESS = 'FETCH_TRIP_STOPS_SUCCESS';
export const FETCH_TRIP_STOPS_FAILED = 'FETCH_TRIP_STOPS_FAILED';

export interface FetchingTripsAction {
  type: typeof FETCHING_TRIPS;
}

export interface FetchTripsFailedAction {
  type: typeof FETCH_TRIPS_FAILED;
  error: string;
}

export interface FetchTripsSuccessAction {
  type: typeof FETCH_TRIPS_SUCCESS;
  trips: Trip[];
}

export type FetchTripsTypes = FetchingTripsAction | FetchTripsFailedAction | FetchTripsSuccessAction;

export interface FetchingTripStopsAction {
  type: typeof FETCHING_TRIP_STOPS;
}

export interface FetchTripStopsFailedAction {
  type: typeof FETCH_TRIP_STOPS_FAILED;
  error: string;
}

export interface FetchTripStopsSuccessAction {
  type: typeof FETCH_TRIP_STOPS_SUCCESS;
  stops: Stop[];
}

export type FetchTripStopsTypes = FetchingTripStopsAction | FetchTripStopsFailedAction | FetchTripStopsSuccessAction;

export const CREATING_TRIP = 'CREATING_TRIP';
export const CREATE_TRIP_SUCCESS = 'CREATE_TRIP_SUCCESS';
export const CREATE_TRIP_FAILED = 'CREATE_TRIP_FAILED';

export interface CreatingTripAction {
  type: typeof CREATING_TRIP;
}

export interface CreateTripSuccessAction {
  type: typeof CREATE_TRIP_SUCCESS;
  trips: Trip[];
}

export interface CreateTripFailedAction {
  type: typeof CREATE_TRIP_FAILED;
  error: string;
}

export type CreateTripTypes = CreatingTripAction | CreateTripSuccessAction | CreateTripFailedAction;

export const CREATING_TRIP_OFFER = 'CREATING_TRIP_OFFER';
export const CREATE_TRIP_OFFER_SUCCESS = 'CREATE_TRIP_OFFER_SUCCESS';
export const CREATE_TRIP_OFFER_FAILED = 'CREATE_TRIP_OFFER_FAILED';

export interface CreatingTripOfferAction {
  type: typeof CREATING_TRIP_OFFER;
}

export interface CreateTripOfferSuccessAction {
  type: typeof CREATE_TRIP_OFFER_SUCCESS;
  updatedTrip: Trip;
}

export interface CreateTripOfferFailedAction {
  type: typeof CREATE_TRIP_OFFER_FAILED;
  error: string;
}

export type CreateTripOfferTypes = CreatingTripOfferAction | CreateTripOfferSuccessAction | CreateTripOfferFailedAction;

export const DELETING_TRIP_OFFER = 'DELETING_TRIP_OFFER';
export const DELETE_TRIP_OFFER_SUCCESS = 'DELETE_TRIP_OFFER_SUCCESS';
export const DELETE_TRIP_OFFER_FAILED = 'DELETE_TRIP_OFFER_FAILED';

export interface DeletingTripOfferAction {
  type: typeof DELETING_TRIP_OFFER;
}

export interface DeleteTripOfferSuccessAction {
  type: typeof DELETE_TRIP_OFFER_SUCCESS;
  tripOfferId: number;
}

export interface DeleteTripOfferFailedAction {
  type: typeof DELETE_TRIP_OFFER_FAILED;
  error: string;
}

export type DeleteTripOfferTypes = DeletingTripOfferAction | DeleteTripOfferSuccessAction | DeleteTripOfferFailedAction;

export type TripsActionTypes =
  | FetchTripsTypes
  | CreateTripTypes
  | CreateTripOfferTypes
  | DeleteTripOfferTypes
  | FetchTripStopsTypes;

// #endregion Trips

export type AppActions =
  | AuthDataTypes
  | ToastActionTypes
  | CouriersActionTypes
  | WarehousesActionTypes
  | TransshipmentsActionTypes
  | ShippersActionTypes
  | DeliveryOrdersActionTypes
  | CustomersActionTypes
  | TripsActionTypes;
