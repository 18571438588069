import { useEffect, useState, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../store/config/types';
import { fetchTransshipments } from '../../store/actions/transshipments.actions';
import AddButton from '../../components/CustomButtons/AddButton';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Pagination from '../../components/Pagination/Pagination';
import SpecialInput from '../../components/SpecialInput/Input';
import Spinner from '../../components/Spinner/Spinner';
import TableList from '../../components/TableList/TableList';

const PAGE_LIMIT: number = 12;

const mapStateToProps = (state: RootState) => {
  return {
    transshipments: state?.transshipments,
    loggedIn: state.auth.loggedIn,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  fetchTransshipments: () => dispatch(fetchTransshipments()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function TransshipmentLocation({ fetchTransshipments, transshipments, loggedIn }: PropsFromRedux) {
  const history = useHistory();
  const [, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (
      !transshipments.loadingTransshipments &&
      !transshipments.transshipments &&
      !transshipments.transshipmentsErrorMessage
    ) {
      fetchTransshipments();
    }
  }, [
    fetchTransshipments,
    transshipments.transshipments,
    transshipments.transshipmentsErrorMessage,
    transshipments.loadingTransshipments,
    loggedIn,
  ]);

  const handlePageClick = (page: number) => setCurrentPage(page);

  const handleCreateTransshipment = () => history.push('/transshipment-locations/create');

  const handleUpdateTransshipment = (transshipmentId: number) =>
    history.push(`/transshipment-locations/${transshipmentId}/update`);

  const searchTextHandler = (text: string) => setSearchText(text);

  const filters = (
    <GridContainer>
      <GridItem xs={12} sm={12} md={4}>
        <SpecialInput
          element={{
            elementType: 'input',
            elementConfig: { type: 'text', placeholder: 'Search..' },
            value: searchText,
            validation: {},
          }}
          onChange={(e) => searchTextHandler(e)}
        />
      </GridItem>
    </GridContainer>
  );

  let transshipmentsContent = null;
  if (transshipments?.transshipments) {
    let transshipmentsToShow = transshipments?.transshipments || [];

    if (searchText !== '') {
      transshipmentsToShow = transshipmentsToShow?.filter((transshipment) => {
        return ['description', 'address'].some((key) => {
          return (transshipment as any)[key]?.toLowerCase().includes(searchText.toLowerCase());
        });
      });
    }

    const options = transshipmentsToShow.map((transshipment) => {
      return [
        transshipment.transshipmentId,
        transshipment.description,
        transshipment?.location?.streetAddress,
        transshipment.availability ? 'Available' : 'Not Available',
        transshipment.cost,
      ];
    });

    transshipmentsContent = (
      <TableList
        title="Transshipment"
        head={['Id', 'Description', 'Address', 'Availability', 'Cost', '']}
        onEdit={handleUpdateTransshipment}
        edit
        filters={filters}
        data={options}
        pagination={
          <Pagination
            totalRecords={transshipmentsToShow?.length}
            pageLimit={PAGE_LIMIT}
            pageNeighbours={10}
            onPageChanged={handlePageClick}
          />
        }
      />
    );
  } else if (transshipments.loadingTransshipments) {
    transshipmentsContent = <Spinner />;
  } else {
    transshipmentsContent = <div>No data to show</div>;
  }

  if (transshipments?.loadingTransshipments) {
    return <Spinner />;
  }

  return (
    <Fragment>
      {transshipmentsContent}
      <AddButton onClick={handleCreateTransshipment} />
    </Fragment>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TransshipmentLocation);
