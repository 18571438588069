import { Dispatch } from 'redux';
import { deliveryOrdersService } from '../../services/deliveryOrders.service';
import { DeliveryOrder } from '../config/types/deliveryOrders.types';
import {
  FETCHING_DELIVERY_ORDERS,
  FETCH_DELIVERY_ORDERS_SUCCESS,
  FETCH_DELIVERY_ORDERS_FAILED,
  FetchDeliveryOrdersTypes,
} from '../config/ActionTypes';

// #region Fetch DeliveryOrders

export const fetchDeliveryOrders = () => {
  return (dispatch: Dispatch<FetchDeliveryOrdersTypes>) => {
    dispatch(fetchingDeliveryOrders());

    return deliveryOrdersService.fetchDeliveryOrders().then(
      (response) => {
        dispatch(fetchDeliveryOrdersSuccess(response!));
      },
      (error) => {
        dispatch(fetchDeliveryOrdersFailed(error));
      },
    );
  };
};

export const fetchingDeliveryOrders = (): FetchDeliveryOrdersTypes => ({
  type: FETCHING_DELIVERY_ORDERS,
});

export const fetchDeliveryOrdersSuccess = (deliveryOrders: DeliveryOrder[]): FetchDeliveryOrdersTypes => ({
  type: FETCH_DELIVERY_ORDERS_SUCCESS,
  deliveryOrders,
});

export const fetchDeliveryOrdersFailed = (error: string): FetchDeliveryOrdersTypes => ({
  type: FETCH_DELIVERY_ORDERS_FAILED,
  error,
});

// #endregion Fetch DeliveryOrders
