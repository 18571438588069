import { CustomersState } from '../config/types';
import {
  CustomersActionTypes,
  FETCHING_CUSTOMERS,
  FETCH_CUSTOMERS_SUCCESS,
  FETCH_CUSTOMERS_FAILED,
} from '../config/ActionTypes';

export const initialState: CustomersState = {
  loadingCustomers: false,
  customersFetched: false,
  customers: null,
  customersShipperId: null,
  customer: null,
  customersErrorMessage: null,
};

export function customersReducer(state = initialState, action: CustomersActionTypes): CustomersState {
  switch (action.type) {
    case FETCHING_CUSTOMERS:
      return {
        ...state,
        customersShipperId: action.shipperId,
        loadingCustomers: true,
        customers: null,
        customersErrorMessage: null,
      };

    case FETCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        loadingCustomers: false,
        customersFetched: true,
        customers: action.customers,
        customersErrorMessage: null,
      };

    case FETCH_CUSTOMERS_FAILED:
      return {
        ...state,
        loadingCustomers: false,
        customersFetched: true,
        customers: null,
        customersErrorMessage: action.error,
      };

    default:
      return state;
  }
}
