import { Dispatch } from 'redux';
import { customersService } from '../../services/customers.service';
import { Customer } from '../config/types';
import {
  CustomersActionTypes,
  FETCHING_CUSTOMERS,
  FETCH_CUSTOMERS_SUCCESS,
  FETCH_CUSTOMERS_FAILED,
} from '../config/ActionTypes';

// #region Fetch Customers

export const fetchCustomers = (shipperId: number) => {
  return (dispatch: Dispatch<CustomersActionTypes>) => {
    dispatch(fetchingCustomers(shipperId));

    return customersService.fetchCustomers(shipperId).then(
      (response) => {
        dispatch(fetchCustomersSuccess(response!));
      },
      (error) => {
        dispatch(fetchCustomersFailed(error));
      },
    );
  };
};

export const fetchingCustomers = (shipperId: number): CustomersActionTypes => ({
  type: FETCHING_CUSTOMERS,
  shipperId,
});

export const fetchCustomersSuccess = (customers: Customer[]): CustomersActionTypes => ({
  type: FETCH_CUSTOMERS_SUCCESS,
  customers,
});

export const fetchCustomersFailed = (error: string): CustomersActionTypes => ({
  type: FETCH_CUSTOMERS_FAILED,
  error,
});

// #endregion Fetch Customers
