import { withStyles, Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import buttonStyle from '../../assets/jss/buttonStyle.jsx';

const AddButton = ({ classes, onClick }) => {
  return (
    <Fab color="primary" aria-label="Add" className={classes.addButton} onClick={onClick}>
      <AddIcon />
    </Fab>
  );
};

export default withStyles(buttonStyle)(AddButton);
