import PropTypes from 'prop-types';
import { withStyles, Table, TableHead, TableRow, TableBody, TableCell, Tooltip, IconButton } from '@material-ui/core';
import { Edit, Close, Description } from '@material-ui/icons';
import tableStyle from '../../assets/jss/tableStyle';

function CustomTable({ classes, tableHead, tableData, tableHeaderColor, ...props }) {
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + 'TableHeader']}>
            <TableRow>
              {tableHead.map((prop, key) => {
                return prop !== 'Id' ? (
                  <TableCell className={classes.tableCell + ' ' + classes.tableHeadCell} key={key}>
                    {prop}
                  </TableCell>
                ) : null;
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((prop, key) => {
            return (
              <TableRow key={key}>
                {prop.map((prop, key) => {
                  if (key !== 0) {
                    if (prop && typeof prop === 'string' && prop.includes('maps.google')) {
                      return (
                        <TableCell className={classes.tableCell} key={key}>
                          <a target="_blank" rel="noreferrer" href={prop}>
                            See on Google maps
                          </a>
                        </TableCell>
                      );
                    } else if (
                      prop &&
                      typeof prop === 'string' &&
                      (prop.includes('jpg') || prop.includes('png') || prop.includes('jpeg'))
                    ) {
                      return (
                        <TableCell className={classes.tableCell} key={key}>
                          <div className={classes.profilePicContainer}>
                            <img className={classes.profilePic} alt="profile-image" src={prop} />
                          </div>
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell className={classes.tableCell} key={key}>
                          {prop}
                        </TableCell>
                      );
                    }
                  } else {
                    return null;
                  }
                })}
                {(props.detail || props.edit || props.delete) && (
                  <TableCell className={classes.tableActions}>
                    {props.detail ? (
                      <Tooltip id="tooltip-top" title="Details" placement="top" classes={{ tooltip: classes.tooltip }}>
                        <IconButton
                          aria-label="Details"
                          className={classes.tableActionButton}
                          onClick={props.onShowDetail.bind(this, prop[0])}
                        >
                          <Description className={classes.tableActionButtonIcon + ' ' + classes.edit} />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    {props.edit ? (
                      <Tooltip id="tooltip-top" title="Edit" placement="top" classes={{ tooltip: classes.tooltip }}>
                        <IconButton
                          aria-label="Edit"
                          className={classes.tableActionButton}
                          onClick={props.onEdit.bind(this, prop[0])}
                        >
                          <Edit className={classes.tableActionButtonIcon + ' ' + classes.edit} />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    {props.delete ? (
                      <Tooltip
                        id="tooltip-top-start"
                        title="Delete"
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <IconButton
                          aria-label="Delete"
                          className={classes.tableActionButton}
                          onClick={props.onDelete.bind(this, prop[0])}
                        >
                          <Close className={classes.tableActionButtonIcon + ' ' + classes.close} />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: 'gray',
};

CustomTable.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf(['warning', 'primary', 'danger', 'success', 'info', 'rose', 'gray']),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))),
};

export default withStyles(tableStyle)(CustomTable);
