import * as Yup from 'yup';
import { VehicleEnum } from '../store/config/enums/vehicle.enum';

const requiredString: string = 'Required field';
const wrongEmailString: string = 'Wrong email format';

const onlyNumbersRegex: RegExp = /^[0-9]+$/;

const LoginSchema = Yup.object().shape({
  email: Yup.string().email(wrongEmailString).required(requiredString),
  password: Yup.string().required(requiredString),
});

const WarehouseSchema = Yup.object().shape({
  name: Yup.string().required(requiredString),
  descriptions: Yup.string(),
  streetAddress: Yup.string().required(requiredString),
  phoneNumber: Yup.string().matches(onlyNumbersRegex).min(10).max(10).required(requiredString),
  pointOfContact: Yup.string().required(requiredString),
  email: Yup.string().email(wrongEmailString).required(requiredString),
  shipperId: Yup.number().required(requiredString),
});

const ShipperSchema = Yup.object().shape({
  firstName: Yup.string().required(requiredString),
  lastName: Yup.string().required(requiredString),
  email: Yup.string().email(wrongEmailString).required(requiredString),
  phoneNumber: Yup.string().matches(onlyNumbersRegex).min(10).max(10).required(requiredString),
  homeAddress: Yup.string().required(requiredString),
  companyName: Yup.string().required(requiredString),
});

const TransshipmentSchema = Yup.object().shape({
  description: Yup.string().required(requiredString),
  streetAddress: Yup.string().required(requiredString),
  availability: Yup.boolean().required(requiredString),
  cost: Yup.number().required(requiredString),
});

const CourierUpdateSchema = Yup.object().shape({
  firstName: Yup.string().required(requiredString),
  lastName: Yup.string().required(requiredString),
  phoneNumber: Yup.string().matches(onlyNumbersRegex).min(10).max(10).required(requiredString),
  email: Yup.string().required(requiredString),
  homeAddress: Yup.string().required(requiredString),
  vehicleType: Yup.string().required(requiredString),
  licensePlate: Yup.string().when(['vehicleType'], {
    is: (vehicleType: VehicleEnum) =>
      [VehicleEnum.MOTORCYCLE, VehicleEnum.CAR, VehicleEnum.TRUCK].includes(vehicleType),
    then: Yup.string().required(requiredString),
    otherwise: Yup.string().nullable(),
  }),
  model: Yup.string().when(['vehicleType'], {
    is: (vehicleType: VehicleEnum) => [VehicleEnum.CAR, VehicleEnum.TRUCK].includes(vehicleType),
    then: Yup.string().required(requiredString),
    otherwise: Yup.string().nullable(),
  }),
  color: Yup.string().when(['vehicleType'], {
    is: (vehicleType: VehicleEnum) => [VehicleEnum.CAR, VehicleEnum.TRUCK].includes(vehicleType),
    then: Yup.string().required(requiredString),
    otherwise: Yup.string().nullable(),
  }),
  capacity: Yup.string().when(['vehicleType'], {
    is: (vehicleType: VehicleEnum) => [VehicleEnum.CAR, VehicleEnum.TRUCK].includes(vehicleType),
    then: Yup.string().required(requiredString),
    otherwise: Yup.string().nullable(),
  }),
  bank: Yup.string().required(requiredString),
  accountNumber: Yup.number().required(requiredString),
  accountType: Yup.string().required(requiredString),
  accountHoldersName: Yup.string().required(requiredString),
  accountRoutingNumber: Yup.number().required(requiredString),
});

const CourierCreateSchema = CourierUpdateSchema.concat(
  Yup.object().shape({ password: Yup.string().required(requiredString) }),
);

const TripSchema = Yup.object().shape({
  date: Yup.string().required(requiredString),
});

const CourierOfferSchema = Yup.object().shape({
  courierId: Yup.number().min(1, requiredString).required(requiredString),
});

const schemas = {
  LoginSchema,
  WarehouseSchema,
  ShipperSchema,
  TransshipmentSchema,
  CourierUpdateSchema,
  CourierCreateSchema,
  TripSchema,
  CourierOfferSchema,
};

export default schemas;
