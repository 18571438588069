import { Grid, withStyles } from '@material-ui/core';

const style = {
  grid: {
    margin: '16px 0px !important',
    width: 'unset',
  },
};

function GridContainer({ classes, children, ...rest }) {
  return (
    <Grid container {...rest} className={classes.grid}>
      {children}
    </Grid>
  );
}

export default withStyles(style)(GridContainer);
