import { Shipper, ShipperRequest } from '../store/config/types/shippers.types';
import axios from './axios';

const fetchShippers = async (): Promise<Shipper[] | undefined> => {
  const res = await axios.get('/shippers');
  return res.data.data;
};

const createShipper = async (createShipperRequest: ShipperRequest): Promise<Shipper | undefined> => {
  const res = await axios.post('/shippers/register', createShipperRequest);
  return res.data.data;
};

const updateShipper = async (updateShipperRequest: ShipperRequest): Promise<Shipper | undefined> => {
  const res = await axios.patch(`/shippers/${updateShipperRequest.shipperId}`, updateShipperRequest);
  return res.data.data;
};

const deleteShipper = async (shipperId: number): Promise<any> => {
  const res = await axios.delete(`/shippers/${shipperId}`);
  return res.data.data;
};

export const shippersService = { fetchShippers, createShipper, updateShipper, deleteShipper };
