import { extendDeliveryOrder } from '../../utils/deliveryOrders.helpers';
import {
  DeliveryOrdersActionTypes,
  FETCHING_DELIVERY_ORDERS,
  FETCH_DELIVERY_ORDERS_SUCCESS,
  FETCH_DELIVERY_ORDERS_FAILED,
} from '../config/ActionTypes';
import { DeliveryOrder, DeliveryOrdersState, ExtendedDeliveryOrder } from '../config/types/deliveryOrders.types';

export const initialState: DeliveryOrdersState = {
  loadingDeliveryOrders: false,
  deliveryOrdersFetched: false,
  deliveryOrders: null,
  deliveryOrder: null,
  deliveryOrdersErrorMessage: null,
};

export function deliveryOrdersReducer(state = initialState, action: DeliveryOrdersActionTypes): DeliveryOrdersState {
  switch (action.type) {
    case FETCHING_DELIVERY_ORDERS:
      return {
        ...state,
        loadingDeliveryOrders: true,
        deliveryOrders: null,
        deliveryOrdersErrorMessage: null,
      };

    case FETCH_DELIVERY_ORDERS_SUCCESS:
      const extendedDeliveryOrders: ExtendedDeliveryOrder[] = action.deliveryOrders.map(
        (deliveryOrder: DeliveryOrder) => {
          return extendDeliveryOrder(deliveryOrder);
        },
      );

      return {
        ...state,
        loadingDeliveryOrders: false,
        deliveryOrdersFetched: true,
        deliveryOrders: extendedDeliveryOrders,
        deliveryOrdersErrorMessage: null,
      };

    case FETCH_DELIVERY_ORDERS_FAILED:
      return {
        ...state,
        loadingDeliveryOrders: false,
        deliveryOrdersFetched: true,
        deliveryOrders: null,
        deliveryOrdersErrorMessage: action.error,
      };

    default:
      return state;
  }
}
