import { FormControl, MenuItem } from '@mui/material';
import { AccountType } from '../../store/config/enums/bank.enum';
import SelectField from '../SpecialInput/SelectField';

const BankSelect = ({ ...props }) => {
  const { value, valueChangedHandler } = props;

  return (
    <FormControl variant="standard" fullWidth className="account-type-select">
      <SelectField
        id="account-type-select"
        value={value}
        placeholder="Account type"
        name="accountType"
        onChange={(event) => valueChangedHandler(event, 'accountType')}
        defaultValue=""
      >
        <MenuItem value={AccountType.SAVINGS}>{AccountType[AccountType.SAVINGS]}</MenuItem>
        <MenuItem value={AccountType.CHECKING}>{AccountType[AccountType.CHECKING]}</MenuItem>
      </SelectField>
    </FormControl>
  );
};

export default BankSelect;
