import { Transshipment, TransshipmentsState } from '../config/types';
import {
  TransshipmentsActionTypes,
  FETCHING_TRANSSHIPMENTS,
  FETCH_TRANSSHIPMENTS_SUCCESS,
  FETCH_TRANSSHIPMENTS_FAILED,
  CREATING_TRANSSHIPMENT,
  CREATE_TRANSSHIPMENT_SUCCESS,
  CREATE_TRANSSHIPMENT_FAILED,
  UPDATING_TRANSSHIPMENT,
  UPDATE_TRANSSHIPMENT_SUCCESS,
  UPDATE_TRANSSHIPMENT_FAILED,
  SET_TRANSSHIPMENT,
  UNSET_TRANSSHIPMENT,
} from '../config/ActionTypes';

export const initialState: TransshipmentsState = {
  loadingTransshipments: false,
  transshipmentsFetched: false,
  transshipments: null,
  transshipment: null,
  transshipmentsErrorMessage: null,
  creatingTransshipment: false,
  createTransshipmentSuccess: false,
  createTransshipmentErrorMessage: null,
  updatingTransshipment: false,
  updateTransshipmentSuccess: false,
  updateTransshipmentErrorMessage: null,
};

export function transshipmentsReducer(state = initialState, action: TransshipmentsActionTypes): TransshipmentsState {
  switch (action.type) {
    case FETCHING_TRANSSHIPMENTS:
      return {
        ...state,
        loadingTransshipments: true,
        transshipments: null,
        transshipmentsErrorMessage: null,
      };

    case FETCH_TRANSSHIPMENTS_SUCCESS:
      return {
        ...state,
        loadingTransshipments: false,
        transshipmentsFetched: true,
        transshipments: action.transshipments,
        transshipmentsErrorMessage: null,
        updateTransshipmentSuccess: false,
      };

    case FETCH_TRANSSHIPMENTS_FAILED:
      return {
        ...state,
        loadingTransshipments: false,
        transshipmentsFetched: false,
        transshipments: null,
        transshipmentsErrorMessage: action.error,
      };

    case SET_TRANSSHIPMENT:
      const newTransshipment =
        state.transshipments?.find((p: Transshipment) => p.transshipmentId === Number(action.transshipmentId)) || null;
      return {
        ...state,
        transshipment: newTransshipment,
      };

    case UNSET_TRANSSHIPMENT:
      return {
        ...state,
        transshipment: null,
      };

    case CREATING_TRANSSHIPMENT:
      return {
        ...state,
        creatingTransshipment: true,
        createTransshipmentSuccess: false,
        createTransshipmentErrorMessage: null,
      };

    case CREATE_TRANSSHIPMENT_SUCCESS:
      const newTransshipments = state.transshipments ? [...state.transshipments] : [];
      newTransshipments.push(action.transshipment);

      return {
        ...state,
        transshipments: newTransshipments,
        creatingTransshipment: false,
        createTransshipmentSuccess: true,
        createTransshipmentErrorMessage: null,
      };

    case CREATE_TRANSSHIPMENT_FAILED:
      return {
        ...state,
        creatingTransshipment: false,
        createTransshipmentSuccess: false,
        createTransshipmentErrorMessage: action.error,
      };

    case UPDATING_TRANSSHIPMENT:
      return {
        ...state,
        updatingTransshipment: true,
        updateTransshipmentSuccess: false,
        updateTransshipmentErrorMessage: null,
      };

    case UPDATE_TRANSSHIPMENT_SUCCESS:
      const updatedTransshipments =
        state.transshipments &&
        state.transshipments.map((item) => {
          if (item.transshipmentId !== action.transshipment.transshipmentId) {
            return item;
          }
          return {
            ...action.transshipment,
          };
        });

      return {
        ...state,
        updatingTransshipment: false,
        updateTransshipmentSuccess: true,
        transshipments: updatedTransshipments,
        updateTransshipmentErrorMessage: null,
      };

    case UPDATE_TRANSSHIPMENT_FAILED:
      return {
        ...state,
        updatingTransshipment: false,
        updateTransshipmentSuccess: false,
        updateTransshipmentErrorMessage: action.error,
      };

    default:
      return state;
  }
}
