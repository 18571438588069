import axios from './axios';
import { LoginRequest } from '../store/config/types/auth.types';

async function login({
  email,
  password,
  device = 'admin',
  messagingToken = '',
  loginType = 'SUPER_ADMIN',
  userType = 'SUPER_ADMIN',
}: LoginRequest) {
  const res = await axios.post('/auth/login', { email, password, device, messagingToken, loginType, userType });
  return res.data;
}

async function logout() {
  const res = await axios.get('/auth/logout');
  return res.data;
}

async function getAccount() {
  const res = await axios.get('/account');
  return res.data;
}

export const authService = { login, logout, getAccount };
