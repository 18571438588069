import { useEffect, useCallback } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../store/config/types';
import { AppActions } from '../store/config/ActionTypes';
import * as actions from '../store/actions/auth.actions';
import indexRoutes from '../utils/routes';
import Layout from '../components/Layout/Layout';
import Spinner from '../components/Spinner/Spinner';
import Login from './Auth/Login';

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  logout: () => dispatch(actions.logout()),
  persistLogin: (token: string) => dispatch(actions.persistLogin(token)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function Main({ auth, persistLogin }: PropsFromRedux) {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentURL = window.location.href;

  useEffect(() => {
    if (!auth.loggedIn) {
      const token = localStorage.getItem('token');
      if (!token) {
        if (!currentURL.includes('login')) {
          history.push('/login');
          location.reload();
        }
      } else {
        persistLogin(token);
      }
    } else if (currentURL.includes('login')) {
      history.push('/couriers');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history, auth.loggedIn]);

  const handleOnLogout = useCallback(() => {
    history.push('/login');
    dispatch(actions.logout());
  }, [dispatch, history]);

  return (
    <>
      {!auth.loggedIn && !currentURL.includes('login') ? (
        <div className="SpinnerContainer">
          <Spinner />
        </div>
      ) : (
        <Switch>
          <Route path="/login" component={Login} />
          <Layout onLogout={handleOnLogout}>
            {indexRoutes.map((route, key) => (
              <Route exact path={route.path} component={route.component} key={key} />
            ))}
            <Route exact path="/">
              <Redirect to="/couriers" />
            </Route>
            {!auth.loggedIn && <Redirect from="/" to="/login" />}
          </Layout>
        </Switch>
      )}
    </>
  );
}

export default connector(Main);
