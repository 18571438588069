import axios from './axios';
import { Warehouse } from '../store/config/types/warehouses.types';

async function fetchWarehouses(): Promise<Warehouse[] | undefined> {
  const res = await axios.get('/warehouses');
  return res.data.data;
}

async function createWarehouse(createWarehouseRequest: Warehouse): Promise<Warehouse | undefined> {
  const res = await axios.post('/warehouses', createWarehouseRequest);
  return res.data.data;
}

async function updateWarehouse(warehouseId: number, updateWarehouseRequest: Warehouse): Promise<Warehouse | undefined> {
  const res = await axios.patch(`/warehouses/${warehouseId}`, updateWarehouseRequest);
  return res.data.data;
}

async function deleteWarehouse(warehouseId: number): Promise<boolean | undefined> {
  const res = await axios.delete(`/warehouses/${warehouseId}`);
  return res.data.data;
}

export const warehousesService = { fetchWarehouses, createWarehouse, updateWarehouse, deleteWarehouse };
