import { useEffect, useState, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import moment from 'moment';
import { RootState } from '../../store/config/types';
import { ExtendedDeliveryOrder } from '../../store/config/types/deliveryOrders.types';
import { Shipper } from '../../store/config/types/shippers.types';
import { Warehouse } from '../../store/config/types/warehouses.types';
import { fetchDeliveryOrders } from '../../store/actions/deliveryOrders.actions';
import { fetchShippers } from '../../store/actions/shippers.actions';
import { fetchWarehouses } from '../../store/actions/warehouses.actions';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Pagination from '../../components/Pagination/Pagination';
import SpecialInput from '../../components/SpecialInput/Input';
import Spinner from '../../components/Spinner/Spinner';
import TableList from '../../components/TableList/TableList';

const PAGE_LIMIT: number = 12;

const mapStateToProps = (state: RootState) => {
  return {
    deliveryOrders: state.deliveryOrders,
    warehouses: state.warehouses,
    shippers: state.shippers,
    loggedIn: state.auth.loggedIn,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  fetchDeliveryOrders: () => dispatch(fetchDeliveryOrders()),
  fetchWarehouses: () => dispatch(fetchWarehouses()),
  fetchShippers: () => dispatch(fetchShippers()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function DeliveryOrders({
  deliveryOrders,
  warehouses,
  shippers,
  loggedIn,
  fetchDeliveryOrders,
  fetchWarehouses,
  fetchShippers,
}: PropsFromRedux) {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    if (
      !deliveryOrders.loadingDeliveryOrders &&
      !deliveryOrders.deliveryOrders &&
      !deliveryOrders.deliveryOrdersErrorMessage
    ) {
      fetchDeliveryOrders();
    }
  }, [
    fetchDeliveryOrders,
    deliveryOrders.deliveryOrders,
    deliveryOrders.deliveryOrdersErrorMessage,
    deliveryOrders.loadingDeliveryOrders,
    loggedIn,
  ]);

  useEffect(() => {
    if (
      !warehouses.loadingWarehouses &&
      !warehouses.warehouses &&
      !warehouses.warehousesFetched &&
      !warehouses.warehousesErrorMessage
    ) {
      fetchWarehouses();
    }
  }, [
    fetchWarehouses,
    warehouses.loadingWarehouses,
    warehouses.warehouses,
    warehouses.warehousesFetched,
    warehouses.warehousesErrorMessage,
  ]);

  useEffect(() => {
    if (
      !shippers.loadingShippers &&
      !shippers.shippers &&
      !shippers.shippersFetched &&
      !shippers.shippersErrorMessage
    ) {
      fetchShippers();
    }
  }, [
    fetchShippers,
    shippers.loadingShippers,
    shippers.shippers,
    shippers.shippersFetched,
    shippers.shippersErrorMessage,
  ]);

  const handlePageClick = (page: number) => setCurrentPage(page);

  const handleUpdateDeliveryOrder = (deliveryOrderId: string) =>
    history.push(`/delivery-orders/${deliveryOrderId}/detail`);

  const searchTextHandler = (text: string) => setSearchText(text);

  const filters = (
    <GridContainer>
      <GridItem xs={12} sm={12} md={4}>
        <SpecialInput
          element={{
            elementType: 'input',
            elementConfig: { type: 'text', placeholder: 'Search..' },
            value: searchText,
            validation: {},
          }}
          onChange={searchTextHandler}
        />
      </GridItem>
    </GridContainer>
  );

  let deliveryOrdersList = null;

  if (deliveryOrders.deliveryOrders && warehouses.warehouses) {
    let deliveryOrdersToShow: ExtendedDeliveryOrder[] = deliveryOrders.deliveryOrders || [];

    if (searchText !== '') {
      deliveryOrdersToShow = deliveryOrdersToShow.filter((deliveryOrder: ExtendedDeliveryOrder) => {
        const warehouse = warehouses.warehouses?.find(
          (warehouse: Warehouse) => warehouse.warehouseId === deliveryOrder.warehouseId,
        );
        if (warehouse?.name?.toLowerCase().includes(searchText.toLowerCase())) {
          return true;
        }

        const shipper = shippers.shippers?.find((shipper: Shipper) => shipper.shipperId === deliveryOrder.shipperId);
        if (shipper?.companyName?.toLowerCase().includes(searchText.toLowerCase())) {
          return true;
        }

        if (
          moment(deliveryOrder.deliveryExpectedAt).format('MM/DD/YYYY').toLowerCase().includes(searchText.toLowerCase())
        ) {
          return true;
        }

        return ['packagesAmount', 'numberOfRecipients'].some((key) => {
          return (deliveryOrder as any)[key]?.toString().toLowerCase().includes(searchText.toLowerCase());
        });
      });
    }

    const options = deliveryOrdersToShow
      ?.slice((currentPage - 1) * PAGE_LIMIT, currentPage * PAGE_LIMIT)
      .map((deliveryOrder: ExtendedDeliveryOrder) => {
        const warehouse = warehouses.warehouses?.find(
          (warehouse: Warehouse) => warehouse.warehouseId === deliveryOrder.warehouseId,
        );

        const deliveryOrderShipper = shippers.shippers?.find(
          (shipper: Shipper) => shipper.shipperId === warehouse?.shipperId,
        );

        return [
          deliveryOrder.deliveryOrderId,
          deliveryOrderShipper && !!deliveryOrderShipper?.companyName ? deliveryOrderShipper?.companyName : '-',
          warehouse?.name,
          moment.utc(deliveryOrder.deliveryExpectedAt).format('MM/DD/YYYY'),
          deliveryOrder.packagesAmount,
          deliveryOrder.numberOfRecipients,
        ];
      });

    deliveryOrdersList = (
      <TableList
        title="Delivery Orders"
        head={['Id', 'Shipper', 'Warehouse', 'Expected date', 'Number of packages', 'Number of recipients', '']}
        onShowDetail={handleUpdateDeliveryOrder}
        detail
        filters={filters}
        data={options}
        pagination={
          <Pagination
            totalRecords={deliveryOrdersToShow?.length}
            pageLimit={PAGE_LIMIT}
            pageNeighbours={10}
            onPageChanged={handlePageClick}
          />
        }
      />
    );
  } else if (deliveryOrders.loadingDeliveryOrders) {
    deliveryOrdersList = <Spinner />;
  } else {
    deliveryOrdersList = <div>No data to show</div>;
  }

  if (!deliveryOrders.deliveryOrdersFetched) {
    return <Spinner />;
  }

  return <Fragment>{deliveryOrdersList}</Fragment>;
}

export default connector(DeliveryOrders);
