import { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { ChevronLeft, ChevronRight, FirstPage, LastPage } from '@material-ui/icons';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';
const FIRST_PAGE = 'FIRST';
const LAST_PAGE = 'LAST';

const pageLimitDefault = 20;

// Helper method for creating a range of numbers: range(1, 5) => [1, 2, 3, 4, 5]
const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

class Pagination extends PureComponent {
  constructor(props) {
    super(props);
    const { totalRecords = null, pageLimit = pageLimitDefault, pageNeighbours = 0 } = props;

    this.state = {
      pageLimit: typeof pageLimit === 'number' ? pageLimit : pageLimitDefault,
      totalRecords: typeof totalRecords === 'number' ? totalRecords : 0,
      pageNeighbours: typeof pageNeighbours === 'number' ? Math.max(0, Math.min(pageNeighbours, 3)) : 0,
      totalPages: Math.ceil(totalRecords / pageLimit),
      currentPage: 1,
    };
  }

  /**
   * Let's say we have 10 pages and we set pageNeighbours to 2
   * Given that the current page is 6
   * The pagination control will look like the following:
   *
   * (1) < {4 5} [6] {7 8} > (10)
   *
   * (x) => terminal pages: first and last page(always visible)
   * [x] => represents current page
   * {...x} => represents page neighbours
   */
  fetchPageNumbers = () => {
    const totalPages = this.state.totalPages;
    const currentPage = this.state.currentPage;
    const pageNeighbours = this.state.pageNeighbours;

    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = this.state.pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(1, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages, currentPage + pageNeighbours);

      let pages = range(startPage, endPage);

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 1;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      // handle: (1) < {5 6} [7] {8 9} (10)
      if (hasLeftSpill && !hasRightSpill) {
        const extraPages = range(startPage - spillOffset, startPage - 1);
        pages = [LEFT_PAGE, ...extraPages, ...pages];
      }

      // handle: (1) {2 3} [4] {5 6} > (10)
      else if (!hasLeftSpill && hasRightSpill) {
        const extraPages = range(endPage + 1, endPage + spillOffset);
        pages = [...pages, ...extraPages, RIGHT_PAGE];
      }

      // handle: (1) < {4 5} [6] {7 8} > (10)
      else if (hasLeftSpill && hasRightSpill) {
        pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
      }

      if (hasLeftSpill) {
        pages = [FIRST_PAGE, ...pages];
      }

      if (hasRightSpill) {
        pages = [...pages, LAST_PAGE];
      }

      return pages;
    }
    return range(1, totalPages);
  };

  componentDidMount() {
    this.gotoPage(1);
  }

  static getDerivedStateFromProps(props, currentState) {
    if (currentState.totalRecords !== props.totalRecords) {
      return {
        totalRecords: props.totalRecords,
        totalPages: Math.ceil(props.totalRecords / props.pageLimit),
        currentPage: 1,
      };
    }
    return null;
  }

  gotoPage = (page) => {
    const { onPageChanged = (f) => f } = this.props;

    const currentPage = Math.max(0, Math.min(page, this.state.totalPages));

    this.setState({ currentPage }, () => onPageChanged(currentPage));
  };

  handleClick = (page) => (evt) => {
    evt.preventDefault();
    this.gotoPage(page);
  };

  handleMoveLeft = (evt) => {
    evt.preventDefault();
    this.gotoPage(this.state.currentPage - this.state.pageNeighbours * 2 - 1);
  };

  handleMoveRight = (evt) => {
    evt.preventDefault();
    this.gotoPage(this.state.currentPage + this.state.pageNeighbours * 2 + 1);
  };

  handleMoveFirst = (evt) => {
    evt.preventDefault();
    this.gotoPage(1);
  };

  handleMoveLast = (evt) => {
    evt.preventDefault();
    this.gotoPage(this.state.totalPages);
  };

  render() {
    if (!this.state.totalRecords || this.state.totalPages === 1) return null;

    const { currentPage } = this.state;
    const pages = this.fetchPageNumbers();

    return (
      <Fragment>
        <ul className="Pagination">
          {pages.map((page, index) => {
            if (page === LEFT_PAGE)
              return (
                <li key={index} className="PageItem" onClick={this.handleMoveLeft}>
                  <a className="PageLink" href="#" aria-label="Previous">
                    <ChevronLeft className="ChevronIcon" />
                  </a>
                </li>
              );

            if (page === RIGHT_PAGE)
              return (
                <li key={index} className="PageItem" onClick={this.handleMoveRight}>
                  <a className="PageLink" href="#" aria-label="Next">
                    <ChevronRight className="ChevronIcon" />
                  </a>
                </li>
              );

            if (page === FIRST_PAGE)
              return (
                <li key={index} className="PageItem" onClick={this.handleMoveFirst}>
                  <a className="PageLink" href="#" aria-label="First">
                    <FirstPage className="ChevronIcon" />
                  </a>
                </li>
              );

            if (page === LAST_PAGE)
              return (
                <li key={index} className="PageItem" onClick={this.handleMoveLast}>
                  <a className="PageLink" href="#" aria-label="First">
                    <LastPage className="ChevronIcon" />
                  </a>
                </li>
              );

            return (
              <li
                key={index}
                className={currentPage === page ? 'Active PageItem' : 'PageItem'}
                onClick={this.handleClick(page)}
              >
                <a className="PageLink" href="#">
                  {page}
                </a>
              </li>
            );
          })}
        </ul>
      </Fragment>
    );
  }
}

Pagination.propTypes = {
  // total number of records
  totalRecords: PropTypes.number.isRequired,
  // records per page
  pageLimit: PropTypes.number,
  // number of additional page numbers to show on each side of the current page
  pageNeighbours: PropTypes.number,
  onPageChanged: PropTypes.func,
};

export default Pagination;
