import { Component } from 'react';
import { withStyles, Hidden } from '@material-ui/core';
import Logout from '@material-ui/icons/ExitToApp';
import Button from '../../CustomButtons/Button';
import headerLinkStyle from '../../../assets/jss/headerLinkStyle.jsx';

class HeaderLinks extends Component {
  state = {
    open: false,
  };

  handleToggle = () => {
    this.setState((state) => ({ open: !state.open }));
  };

  handleClose = (event) => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Button
          color={window.innerWidth > 959 ? 'transparent' : 'white'}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-label="Person"
          className={classes.buttonLink}
          onClick={this.props.onLogoutClick}
        >
          <Logout className={classes.icons} />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Profile</p>
          </Hidden>
        </Button>
      </div>
    );
  }
}

export default withStyles(headerLinkStyle)(HeaderLinks);
