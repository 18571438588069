import { useField } from 'formik';
import CustomInput from '../CustomInput/CustomInput';

function InputField({ ...props }) {
  const [field, meta] = useField(props);

  if (field.name === 'licensePlate') {
    const baseOnChange = field.onChange;

    field.onChange = (event) => {
      event.target.value = event.target.value.replace(/[^a-z0-9]/gi, '');
      baseOnChange(event);
    };
  }

  return (
    <CustomInput
      labelText={props.placeholder}
      id={field.name}
      formControlProps={{ fullWidth: true }}
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error}
      inputProps={{ ...field, ...props }}
    />
  );
}

export default InputField;
