import { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { InputLabel, MenuItem, FormControl, Select, OutlinedInput } from '@material-ui/core';

class OutlinedSelect extends Component {
  state = {
    labelWidth: 0,
  };

  componentDidMount() {
    this.setState({
      // eslint-disable-next-line
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
    });
  }

  render() {
    let optionMenuItems = this.props.options?.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });

    return (
      <FormControl
        variant="outlined"
        margin="dense"
        className="OutlinedSelectFormControl"
        disabled={this.props.disabled}
      >
        <InputLabel
          ref={(ref) => {
            this.InputLabelRef = ref;
          }}
          htmlFor="outlined-age-simple"
        >
          {this.props.label}
        </InputLabel>
        <Select
          name={this.props.name}
          value={this.props.value}
          onChange={this.props.onChange}
          input={<OutlinedInput labelWidth={this.state.labelWidth} name="age" id="outlined-age-simple" />}
        >
          {optionMenuItems}
        </Select>
      </FormControl>
    );
  }
}

OutlinedSelect.propTypes = {
  label: PropTypes.string,
  value: PropTypes.node,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.node,
    }),
  ),
  onChange: PropTypes.func,
};

export default OutlinedSelect;
