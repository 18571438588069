import axios from './axios';
import { Transshipment } from '../store/config/types';

async function fetchTransshipments(): Promise<Transshipment[] | undefined> {
  const res = await axios.get('/transshipments');
  return res.data.data;
}

async function createTransshipment(createTransshipmentRequest: Transshipment): Promise<Transshipment | undefined> {
  const res = await axios.post('/transshipments', createTransshipmentRequest);
  return res.data.data;
}

async function updateTransshipment(
  transshipmentId: number,
  updateTransshipmentRequest: Transshipment,
): Promise<Transshipment | undefined> {
  const res = await axios.patch(`/transshipments/${transshipmentId}`, updateTransshipmentRequest);
  return res.data.data;
}

export const transshipmentsService = { fetchTransshipments, createTransshipment, updateTransshipment };
