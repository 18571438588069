import { useEffect, useState, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../store/config/types';
import { Shipper } from '../../store/config/types/shippers.types';
import { Warehouse } from '../../store/config/types/warehouses.types';
import { fetchShippers } from '../../store/actions/shippers.actions';
import { fetchWarehouses } from '../../store/actions/warehouses.actions';
import { maskPhone } from '../../utils/string.helpers';
import AddButton from '../../components/CustomButtons/AddButton';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Pagination from '../../components/Pagination/Pagination';
import SpecialInput from '../../components/SpecialInput/Input';
import Spinner from '../../components/Spinner/Spinner';
import TableList from '../../components/TableList/TableList';

const PAGE_LIMIT: number = 12;

const mapStateToProps = (state: RootState) => {
  return {
    shippers: state.shippers,
    warehouses: state.warehouses,
    loggedIn: state.auth.loggedIn,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  fetchWarehouses: () => dispatch(fetchWarehouses()),
  fetchShippers: () => dispatch(fetchShippers()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function Warehouses({ shippers, warehouses, loggedIn, fetchWarehouses, fetchShippers }: PropsFromRedux) {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    if (!warehouses.loadingWarehouses && !warehouses.warehouses && !warehouses.warehousesErrorMessage) {
      fetchWarehouses();
    }
  }, [
    fetchWarehouses,
    warehouses.warehouses,
    warehouses.warehousesErrorMessage,
    warehouses.loadingWarehouses,
    loggedIn,
  ]);

  useEffect(() => {
    if (
      !shippers.loadingShippers &&
      !shippers.shippers &&
      !shippers.shippersFetched &&
      !shippers.shippersErrorMessage
    ) {
      fetchShippers();
    }
  }, [
    fetchShippers,
    shippers.loadingShippers,
    shippers.shippers,
    shippers.shippersFetched,
    shippers.shippersErrorMessage,
  ]);

  const handlePageClick = (page: number) => setCurrentPage(page);

  const handleCreateWarehouse = () => history.push('/warehouses/create');

  const handleUpdateWarehouse = (warehouseId: string) => history.push(`/warehouses/${warehouseId}/update`);

  const searchTextHandler = (text: string) => setSearchText(text);

  const filters = (
    <GridContainer>
      <GridItem xs={12} sm={12} md={4}>
        <SpecialInput
          element={{
            elementType: 'input',
            elementConfig: { type: 'text', placeholder: 'Search..' },
            value: searchText,
            validation: {},
          }}
          onChange={searchTextHandler}
        />
      </GridItem>
    </GridContainer>
  );

  let warehousesList = null;

  if (warehouses.warehouses && shippers.shippers) {
    let warehousesToShow: Warehouse[] = warehouses.warehouses || [];

    if (searchText !== '') {
      warehousesToShow = warehousesToShow.filter((warehouse: Warehouse) => {
        return ['name', 'descriptions', 'streetAddress', 'shipper'].some((key) => {
          const searchableText =
            (warehouse && (warehouse as any)[key]) || (warehouse.location && (warehouse as any)?.location[key]);
          return searchableText?.toLowerCase().includes(searchText.toLowerCase());
        });
      });
    }

    const options = warehousesToShow
      ?.slice((currentPage - 1) * PAGE_LIMIT, currentPage * PAGE_LIMIT)
      .map((warehouse: Warehouse) => {
        const warehouseShipper = shippers.shippers?.find(
          (shipper: Shipper) => shipper.shipperId === warehouse.shipperId,
        );

        return [
          warehouse.warehouseId,
          warehouse.name,
          warehouse.location?.streetAddress,
          warehouse?.email,
          maskPhone(warehouse?.phoneNumber),
          warehouseShipper ? `${warehouseShipper?.firstName} ${warehouseShipper?.lastName}` : '-',
          warehouse?.pointOfContact,
          warehouse.descriptions,
        ];
      });

    warehousesList = (
      <TableList
        title="Warehouses"
        head={['Id', 'Name', 'Address', 'Email', 'Phone Number', 'Shipper', 'Contact Person', 'Notes', '']}
        onEdit={handleUpdateWarehouse}
        edit
        filters={filters}
        data={options}
        pagination={
          <Pagination
            totalRecords={warehousesToShow?.length}
            pageLimit={PAGE_LIMIT}
            pageNeighbours={10}
            onPageChanged={handlePageClick}
          />
        }
      />
    );
  } else if (warehouses.loadingWarehouses) {
    warehousesList = <Spinner />;
  } else {
    warehousesList = <div>No data to show</div>;
  }

  if (!warehouses.warehousesFetched) {
    return <Spinner />;
  }

  return (
    <Fragment>
      {warehousesList}
      <AddButton onClick={handleCreateWarehouse} />
    </Fragment>
  );
}

export default connector(Warehouses);
