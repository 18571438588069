import { Dispatch } from 'redux';
import { shippersService } from '../../services/shippers.service';
import { Shipper } from '../config/types/shippers.types';
import {
  CreateShipperTypes,
  CREATE_SHIPPER_FAILED,
  CREATE_SHIPPER_SUCCESS,
  CREATING_SHIPPER,
  DeleteShipperTypes,
  DELETE_SHIPPER_FAILED,
  DELETE_SHIPPER_SUCCESS,
  DELETING_SHIPPER,
  FETCHING_SHIPPERS,
  FetchShippersTypes,
  FETCH_SHIPPERS_FAILED,
  FETCH_SHIPPERS_SUCCESS,
  SetShipperTypes,
  SET_SHIPPER,
  UNSET_SHIPPER,
  UpdateShipperTypes,
  UPDATE_SHIPPER_FAILED,
  UPDATE_SHIPPER_SUCCESS,
  UPDATING_SHIPPER,
} from '../config/ActionTypes';

// #region Fetch Shippers

export const fetchShippers = () => {
  return (dispatch: Dispatch<FetchShippersTypes>) => {
    dispatch(fetchingShippers());

    return shippersService.fetchShippers().then(
      (response) => {
        dispatch(fetchShippersSuccess(response!));
      },
      (error) => {
        dispatch(fetchShippersFailed(error));
      },
    );
  };
};

export const setShipper = (shipperId: number) => {
  return (dispatch: Dispatch<SetShipperTypes>) => {
    if (shipperId) {
      dispatch(setShipperInState(shipperId));
    } else {
      dispatch(unsetShipperInState());
    }
  };
};

export const unsetShipperInState = (): SetShipperTypes => ({
  type: UNSET_SHIPPER,
});

export const setShipperInState = (shipperId: number): SetShipperTypes => ({
  type: SET_SHIPPER,
  shipperId,
});

export const fetchingShippers = (): FetchShippersTypes => ({
  type: FETCHING_SHIPPERS,
});

export const fetchShippersSuccess = (shippers: Shipper[]): FetchShippersTypes => ({
  type: FETCH_SHIPPERS_SUCCESS,
  shippers,
});

export const fetchShippersFailed = (error: string): FetchShippersTypes => ({
  type: FETCH_SHIPPERS_FAILED,
  error,
});

// #endregion Fetch Shippers

// #region Create Shipper

export const createShipper = (shipperRequest: Shipper) => {
  return (dispatch: Dispatch<CreateShipperTypes>) => {
    dispatch(creatingShipper());

    return shippersService.createShipper(shipperRequest).then(
      (response) => {
        dispatch(createShipperSuccess(response!));
      },
      (error) => {
        dispatch(createShipperFailed(error));
      },
    );
  };
};

export const creatingShipper = (): CreateShipperTypes => ({
  type: CREATING_SHIPPER,
});

export const createShipperSuccess = (shipper: Shipper): CreateShipperTypes => ({
  type: CREATE_SHIPPER_SUCCESS,
  shipper,
});

export const createShipperFailed = (error: string): CreateShipperTypes => ({
  type: CREATE_SHIPPER_FAILED,
  error,
});

// #endregion Create Shipper

// #region Update Shipper

export const updateShipper = (shipperRequest: Shipper) => {
  return (dispatch: Dispatch<UpdateShipperTypes>) => {
    dispatch(updatingShipper());

    return shippersService.updateShipper(shipperRequest).then(
      (response) => {
        dispatch(updateShipperSuccess(response!));
      },
      (error) => {
        dispatch(updateShipperFailed(error));
      },
    );
  };
};

export const updatingShipper = (): UpdateShipperTypes => ({
  type: UPDATING_SHIPPER,
});

export const updateShipperSuccess = (shipper: Shipper): UpdateShipperTypes => ({
  type: UPDATE_SHIPPER_SUCCESS,
  shipper,
});

export const updateShipperFailed = (error: string): UpdateShipperTypes => ({
  type: UPDATE_SHIPPER_FAILED,
  error,
});

// #endregion Update Shipper

// #region Delete Shipper

export const deleteShipper = (shipperId: number) => {
  return (dispatch: Dispatch<DeleteShipperTypes>) => {
    dispatch(deletingShipper());

    return shippersService.deleteShipper(shipperId).then(
      () => {
        dispatch(deleteShipperSuccess(shipperId));
      },
      (error) => {
        dispatch(deleteShipperFailed(error));
      },
    );
  };
};

export const deletingShipper = (): DeleteShipperTypes => ({
  type: DELETING_SHIPPER,
});

export const deleteShipperSuccess = (shipperId: number): DeleteShipperTypes => ({
  type: DELETE_SHIPPER_SUCCESS,
  shipperId,
});

export const deleteShipperFailed = (error: string): DeleteShipperTypes => ({
  type: DELETE_SHIPPER_FAILED,
  error,
});

// #endregion Delete Shipper
