import { ChangeEvent } from 'react';
import { TextField, FormControl, makeStyles, createStyles } from '@material-ui/core';

interface DatePickerProps {
  label: string;
  value: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      paddingBottom: '10px',
      margin: '27px 0 0 0',
      position: 'relative',
    },
  }),
);

function DatePicker({ label, value, onChange }: DatePickerProps) {
  const classes = useStyles();

  return (
    <FormControl fullWidth classes={{ root: classes.formControl }}>
      <TextField
        type="date"
        label={label}
        name={label}
        value={value}
        onChange={onChange}
        InputLabelProps={{ shrink: true }}
      />
    </FormControl>
  );
}

export default DatePicker;
