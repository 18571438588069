import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import moment from 'moment';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { RootState } from '../../store/config/types';
import { DeliveryOrder, ExtendedDeliveryOrder } from '../../store/config/types/deliveryOrders.types';
import { Shipper } from '../../store/config/types/shippers.types';
import { Warehouse } from '../../store/config/types/warehouses.types';
import { fetchCustomers } from '../../store/actions/customers.actions';
import { fetchDeliveryOrders } from '../../store/actions/deliveryOrders.actions';
import { fetchShippers } from '../../store/actions/shippers.actions';
import { fetchWarehouses } from '../../store/actions/warehouses.actions';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import PackagesTable from './PackagesTable';

interface DeliveryOrderParams {
  deliveryOrderId?: string;
}

const mapStateToProps = (state: RootState) => ({
  deliveryOrders: state.deliveryOrders,
  warehouses: state.warehouses,
  shippers: state.shippers,
  customers: state.customers,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  fetchDeliveryOrders: () => dispatch(fetchDeliveryOrders()),
  fetchWarehouses: () => dispatch(fetchWarehouses()),
  fetchShippers: () => dispatch(fetchShippers()),
  fetchCustomers: (shipperId: number) => dispatch(fetchCustomers(shipperId)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function DeliveryOrderForm({
  deliveryOrders,
  warehouses,
  shippers,
  customers,
  fetchDeliveryOrders,
  fetchWarehouses,
  fetchShippers,
  fetchCustomers,
}: PropsFromRedux) {
  const history = useHistory();
  const { deliveryOrderId } = useParams<DeliveryOrderParams>();
  const editMode = Boolean(deliveryOrderId);
  const [selectedDeliveryOrder, setSelectedDeliveryOrder] = useState<ExtendedDeliveryOrder | null>(null);

  useEffect(() => {
    if (editMode && selectedDeliveryOrder === null && deliveryOrders.deliveryOrders && deliveryOrderId) {
      const editedDeliveryOrder = deliveryOrders.deliveryOrders.filter(
        (deliveryOrder: DeliveryOrder) => deliveryOrder.deliveryOrderId === Number(deliveryOrderId),
      )[0];
      setSelectedDeliveryOrder(editedDeliveryOrder);
    }
  }, [editMode, selectedDeliveryOrder, deliveryOrders.deliveryOrders, deliveryOrderId, setSelectedDeliveryOrder]);

  useEffect(() => {
    if (!deliveryOrders.deliveryOrders) {
      fetchDeliveryOrders();
    }
  }, [deliveryOrders.deliveryOrders, fetchDeliveryOrders]);

  useEffect(() => {
    if (
      !warehouses.loadingWarehouses &&
      !warehouses.warehouses &&
      !warehouses.warehousesFetched &&
      !warehouses.warehousesErrorMessage
    ) {
      fetchWarehouses();
    }
  }, [
    fetchWarehouses,
    warehouses.loadingWarehouses,
    warehouses.warehouses,
    warehouses.warehousesFetched,
    warehouses.warehousesErrorMessage,
  ]);

  useEffect(() => {
    if (
      !shippers.loadingShippers &&
      !shippers.shippers &&
      !shippers.shippersFetched &&
      !shippers.shippersErrorMessage
    ) {
      fetchShippers();
    }
  }, [
    fetchShippers,
    shippers.loadingShippers,
    shippers.shippers,
    shippers.shippersFetched,
    shippers.shippersErrorMessage,
  ]);

  useEffect(() => {
    if (
      selectedDeliveryOrder &&
      !customers.loadingCustomers &&
      (!customers.customers || customers.customersShipperId !== selectedDeliveryOrder.warehouse.shipperId) &&
      !customers.customersErrorMessage
    ) {
      fetchCustomers(selectedDeliveryOrder.warehouse.shipperId!);
    }
  }, [
    fetchCustomers,
    customers.loadingCustomers,
    customers.customers,
    customers.customersShipperId,
    customers.customersFetched,
    customers.customersErrorMessage,
    selectedDeliveryOrder,
  ]);

  const selectedWarehouse =
    selectedDeliveryOrder &&
    warehouses.warehouses &&
    warehouses.warehouses.find((warehouse: Warehouse) => warehouse.warehouseId === selectedDeliveryOrder.warehouseId);

  const deliveryOrderShipper = shippers.shippers?.find(
    (shipper: Shipper) => shipper.shipperId === selectedDeliveryOrder?.shipperId,
  );

  return (
    <div className="delivery-order-form">
      <Card profile>
        <CardHeader color="primary" className="delivery-order-header">
          <h4>
            {editMode && (
              <span className="go-back" onClick={() => history.goBack()}>
                <ChevronLeft /> Go back
              </span>
            )}
            <span>
              {editMode && selectedDeliveryOrder
                ? `Delivery Order ${selectedDeliveryOrder.deliveryOrderId}`
                : 'New Delivery Order'}
            </span>
            {editMode && <span />}
          </h4>
        </CardHeader>
        <CardBody profile>
          <GridContainer>
            {deliveryOrderShipper && (
              <GridItem xs={12}>
                <div className="shipper-row">
                  <span className="info">
                    <b>Shipper: </b>
                    {deliveryOrderShipper?.companyName}
                  </span>
                </div>
              </GridItem>
            )}
            {warehouses.warehouses && (
              <GridItem xs={12}>
                <div className="warehouse-row">
                  <span className="info">
                    <b>Warehouse: </b>
                    {selectedWarehouse?.name}
                  </span>
                </div>
              </GridItem>
            )}
            <GridItem xs={12} className="left-align">
              <span className="info">
                <b>Date: </b> {moment.utc(selectedDeliveryOrder?.deliveryExpectedAt).format('MM/DD/YYYY')}
              </span>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12}>
              <h3 className="section-title text">Packages</h3>
            </GridItem>
          </GridContainer>
          {selectedDeliveryOrder && <PackagesTable selectedDeliveryOrder={selectedDeliveryOrder} />}
        </CardBody>
      </Card>
    </div>
  );
}

export default connector(DeliveryOrderForm);
