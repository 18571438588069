import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Formik, Form } from 'formik';
import { FormControl, MenuItem } from '@material-ui/core';
import { RootState } from '../../store/config/types';
import { LocationLatLng } from '../../store/config/types/location.types';
import { Shipper } from '../../store/config/types/shippers.types';
import { Warehouse } from '../../store/config/types/warehouses.types';
import { fetchShippers } from '../../store/actions/shippers.actions';
import { setToast } from '../../store/actions/toast.actions';
import { createWarehouse, fetchWarehouses, updateWarehouse } from '../../store/actions/warehouses.actions';
import schemas from '../../utils/schemas';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import Button from '../../components/CustomButtons/Button';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import GoogleAutocomplete from '../../components/SpecialInput/GoogleAutocomplete/GoogleAutocomplete';
import InputField from '../../components/SpecialInput/InputField';
import MaskedPhoneInput from '../../components/MaskedPhoneInput/MaskedPhoneInput';
import SelectField from '../../components/SpecialInput/SelectField';
import Spinner from '../../components/Spinner/Spinner';

interface WarehouseParams {
  warehouseId?: string;
}

interface WarehouseValues {
  name: string;
  descriptions: string;
  streetAddress: string;
  shipperId: number | undefined;
  phoneNumber: string;
  pointOfContact: string;
  email: string;
}

const mapStateToProps = (state: RootState) => ({
  shippers: state.shippers,
  warehouses: state.warehouses,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  onCreateWarehouse: (warehouse: Warehouse) => dispatch(createWarehouse(warehouse)),
  onUpdateWarehouse: (warehouseId: number, warehouse: Warehouse) => dispatch(updateWarehouse(warehouseId, warehouse)),
  fetchWarehouses: () => dispatch(fetchWarehouses()),
  fetchShippers: () => dispatch(fetchShippers()),
  setToast: (message: string, messageType: string) => dispatch(setToast(message, messageType)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function WarehouseForm({
  shippers,
  warehouses,
  onCreateWarehouse,
  onUpdateWarehouse,
  fetchWarehouses,
  fetchShippers,
  setToast,
}: PropsFromRedux) {
  const history = useHistory();
  const { warehouseId } = useParams<WarehouseParams>();
  const editMode: boolean = Boolean(warehouseId);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState<Warehouse | null>(null);
  const [address, setAddress] = useState<string>('');
  const [coordinates, setCoordinates] = useState<LocationLatLng>({ lat: null, lng: null });
  const [city, setCity] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [zipCode, setZipCode] = useState<string>('');

  useEffect(() => {
    if (
      !shippers.loadingShippers &&
      !shippers.shippers &&
      !shippers.shippersFetched &&
      !shippers.shippersErrorMessage
    ) {
      fetchShippers();
    }
  }, [
    fetchShippers,
    shippers.loadingShippers,
    shippers.shippers,
    shippers.shippersFetched,
    shippers.shippersErrorMessage,
  ]);

  useEffect(() => {
    if (editMode && selectedWarehouse === null && warehouses.warehouses && warehouseId) {
      const editedWarehouse = warehouses.warehouses.filter((w: Warehouse) => w.warehouseId === Number(warehouseId))[0];
      setSelectedWarehouse(editedWarehouse);
      setAddress(editedWarehouse.location.streetAddress!);
      setCoordinates({ lat: editedWarehouse.location.latitude, lng: editedWarehouse.location.longitude });
      setCity(editedWarehouse.location.city!);
      setState(editedWarehouse.location.state!);
      setZipCode(editedWarehouse.location.zipCode!);
    }
  }, [editMode, selectedWarehouse, warehouses.warehouses, warehouseId, setSelectedWarehouse]);

  useEffect(() => {
    if (!warehouses.warehouses) {
      fetchWarehouses();
    }
  }, [warehouses.warehouses, fetchWarehouses]);

  useEffect(() => {
    if (submitting) {
      if (warehouses.createWarehouseSuccess || warehouses.updateWarehouseSuccess) {
        setSubmitting(false);
        setToast(`Warehouse successfully ${warehouses.createWarehouseSuccess ? 'created' : 'updated'}`, 'success');
        history.push('/warehouses');
      } else if (warehouses.createWarehouseErrorMessage || warehouses.updateWarehouseErrorMessage) {
        setSubmitting(false);
        setToast(
          `An error has ocurred while ${warehouses.createWarehouseErrorMessage ? 'creating' : 'updating'} warehouse`,
          'danger',
        );
      }
    }
  }, [
    submitting,
    warehouses.createWarehouseSuccess,
    warehouses.updateWarehouseSuccess,
    warehouses.createWarehouseErrorMessage,
    warehouses.updateWarehouseErrorMessage,
    history,
    setSubmitting,
    setToast,
  ]);

  const handleGoBack = () => history.push('/warehouses');

  const submitForm = (values: WarehouseValues) => {
    const warehouseRequest: Warehouse = {
      name: values.name,
      phoneNumber: values.phoneNumber,
      pointOfContact: values.pointOfContact,
      descriptions: values.descriptions,
      email: values.email,
      location: {
        latitude: coordinates.lat!,
        longitude: coordinates.lng!,
        streetAddress: address,
        city: city,
        state: state,
        zipCode: zipCode,
      },
      availability: true,
      shipperId: values.shipperId,
    };

    setSubmitting(true);

    if (editMode) {
      warehouseRequest.warehouseId = selectedWarehouse?.warehouseId;
      onUpdateWarehouse(Number(selectedWarehouse?.warehouseId), warehouseRequest);
    } else {
      warehouseRequest.shipperId = values.shipperId;
      onCreateWarehouse(warehouseRequest);
    }
  };

  if (editMode && !selectedWarehouse) {
    return <Spinner />;
  }

  return (
    <div className="warehouse-form">
      <Card profile>
        <CardHeader color="primary" className="warehouse-header">
          <h4>{editMode && selectedWarehouse ? `${selectedWarehouse.name}` : 'New warehouse'}</h4>
        </CardHeader>
        <CardBody profile>
          <Formik
            initialValues={{
              name: selectedWarehouse ? selectedWarehouse.name! : '',
              descriptions: selectedWarehouse ? selectedWarehouse.descriptions! : '',
              streetAddress: selectedWarehouse ? selectedWarehouse.location.streetAddress! : '',
              phoneNumber: selectedWarehouse ? selectedWarehouse?.phoneNumber! : '',
              pointOfContact: selectedWarehouse ? selectedWarehouse?.pointOfContact! : '',
              email: selectedWarehouse ? selectedWarehouse?.email! : '',
              shipperId: selectedWarehouse ? selectedWarehouse.shipperId! : undefined,
            }}
            validationSchema={schemas.WarehouseSchema}
            onSubmit={submitForm}
          >
            {({ setFieldValue }) => (
              <Form>
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <InputField placeholder="Name" name="name" type="text" />
                  </GridItem>
                  <GoogleAutocomplete
                    address={address}
                    setAddress={setAddress}
                    setCoordinates={setCoordinates}
                    setCity={setCity}
                    setState={setState}
                    setZipCode={setZipCode}
                    setFieldValue={setFieldValue}
                    usesAllGoogleProps={true}
                    inputName={'streetAddress'}
                    placeholderName={'Address'}
                    inputSize={6}
                  />
                  <GridItem xs={12} sm={6}>
                    <InputField placeholder="Email" name="email" type="email" />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <MaskedPhoneInput placeholder="Phone number" name="phoneNumber" setfieldvalue={setFieldValue} />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    {shippers.shippers && (
                      <FormControl variant="standard" fullWidth className="vehicle-type-select">
                        <SelectField
                          placeholder="Shipper"
                          name="shipperId"
                          values={shippers.shippers.map((shipper: Shipper) => (
                            <MenuItem
                              key={shipper.shipperId}
                              value={shipper.shipperId}
                            >{`${shipper.firstName} ${shipper.lastName}`}</MenuItem>
                          ))}
                        />
                      </FormControl>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <InputField placeholder="Contact Person" name="pointOfContact" type="text" />
                  </GridItem>
                  <GridItem xs={12}>
                    <InputField placeholder="Notes" name="descriptions" type="text" />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12}>
                    <div className="btn-group">
                      <Button type="submit" className="submit-button" color="primary">
                        {`${editMode ? 'Update' : 'Create'} warehouse`}
                      </Button>
                      <Button onClick={handleGoBack} className="cancel-button" color="secondary">
                        Cancel
                      </Button>
                    </div>
                  </GridItem>
                </GridContainer>
              </Form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </div>
  );
}

export default connector(WarehouseForm);
