import Couriers from '../screens/Couriers/Couriers';
import CouriersForm from '../screens/Couriers/CouriersForm';
import DeliveryOrders from '../screens/DeliveryOrders/DeliveryOrders';
import DeliveryOrderForm from '../screens/DeliveryOrders/DeliveryOrderForm';
import Shippers from '../screens/Shippers/Shippers';
import ShippersForm from '../screens/Shippers/ShippersForm';
import TransshipmentForm from '../screens/TransshipmentLocations/TransshipmentForm';
import TransshipmentLocation from '../screens/TransshipmentLocations/TransshipmentLocations';
import Warehouses from '../screens/Warehouses/Warehouses';
import WarehouseForm from '../screens/Warehouses/WarehouseForm';
import CouriersSvg from '../assets/images/icon-contact.svg';
import DeliveryOrderSvg from '../assets/images/DeliveryOrderSvg.svg';
import ShippersSvg from '../assets/images/icon-packages.svg';
import TransshipmentLocationsSvg from '../assets/images/icon-location.svg';
import TripsSvg from '../assets/images/icon-motorbike.svg';
import WarehousesSvg from '../assets/images/icon-warehouses.svg';
import Trips from '../screens/Trips/Trips';
import TripsForm from '../screens/Trips/TripsForm';

const routes = [
  {
    path: '/transshipment-locations/create',
    component: TransshipmentForm,
  },
  {
    path: '/transshipment-locations/:transshipmentId/update',
    component: TransshipmentForm,
  },
  {
    path: '/transshipment-locations',
    sidebarName: 'Transshipment Loc.',
    navbarName: 'Transshipment Locations',
    icon: TransshipmentLocationsSvg,
    component: TransshipmentLocation,
  },
  {
    path: '/warehouses',
    sidebarName: 'Warehouses',
    navbarName: 'Warehouses',
    icon: WarehousesSvg,
    component: Warehouses,
  },
  {
    path: '/warehouses/create',
    component: WarehouseForm,
  },
  {
    path: '/warehouses/:warehouseId/update',
    component: WarehouseForm,
  },
  {
    path: '/couriers/create',
    component: CouriersForm,
  },
  {
    path: '/couriers/:courierId/update',
    component: CouriersForm,
  },
  {
    path: '/couriers',
    sidebarName: 'Couriers',
    navbarName: 'Couriers',
    icon: CouriersSvg,
    component: Couriers,
  },
  {
    path: '/shippers',
    sidebarName: 'Shippers',
    navbarName: 'Shippers',
    icon: ShippersSvg,
    component: Shippers,
  },
  {
    path: '/shippers/create',
    component: ShippersForm,
  },
  {
    path: '/shippers/:shipperId/update',
    component: ShippersForm,
  },
  {
    path: '/trips',
    sidebarName: 'Trips',
    navbarName: 'Trips',
    icon: TripsSvg,
    component: Trips,
  },
  {
    path: '/trips/create',
    component: TripsForm,
  },
  {
    path: '/delivery-orders',
    sidebarName: 'Delivery Orders',
    navbarName: 'Delivery Orders',
    icon: DeliveryOrderSvg,
    component: DeliveryOrders,
  },
  {
    path: '/delivery-orders/:deliveryOrderId/detail',
    component: DeliveryOrderForm,
  },
];

export default routes;
