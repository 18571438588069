import { Dispatch, SetStateAction } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng, Suggestion } from 'react-places-autocomplete';
import GridItem from '../../Grid/GridItem';
import InputField from '../InputField';

interface GoogleAutocompleteProps {
  address: string;
  setAddress: Dispatch<SetStateAction<string>>;
  setCoordinates?: Dispatch<any>;
  setCity?: Dispatch<SetStateAction<string>>;
  setState?: Dispatch<SetStateAction<string>>;
  setZipCode?: Dispatch<SetStateAction<string>>;
  // eslint-disable-next-line no-unused-vars
  setFieldValue: (field: string, value: string) => void;
  usesAllGoogleProps: boolean;
  inputSize: number;
  inputName: string;
  placeholderName: string;
}

function GoogleAutocomplete({
  address,
  setAddress,
  setCoordinates,
  setCity,
  setState,
  setZipCode,
  setFieldValue,
  usesAllGoogleProps,
  inputSize,
  inputName,
  placeholderName,
}: GoogleAutocompleteProps) {
  const onSelectValue = async (value: string) => {
    const result = await geocodeByAddress(value);
    setAddress(result[0].formatted_address);

    if (usesAllGoogleProps) {
      const latLng = await getLatLng(result[0]);

      setCoordinates && setCoordinates(latLng);
      setCity && setCity(result[0]?.address_components[3]?.long_name);
      setState && setState(result[0]?.address_components[5]?.long_name);
      setZipCode && setZipCode(result[0]?.address_components[7]?.long_name);
    }
    // eslint-disable-next-line no-unused-vars
    setFieldValue(inputName, result[0].formatted_address);
  };

  return (
    <GridItem xs={12} sm={inputSize}>
      <PlacesAutocomplete value={address} onChange={setAddress} onSelect={onSelectValue}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="search-address-div">
            <InputField {...getInputProps({ placeholder: placeholderName, name: inputName, type: 'text' })} />
            <div className="search-address">
              {loading ? (
                <div>...loading</div>
              ) : (
                suggestions.map((suggestion: Suggestion, index: number) => {
                  const style = { backgroundColor: suggestion.active ? '#7FC15E' : '#ffffff' };
                  return (
                    <div
                      className="search-address-content"
                      // @ts-ignore:next-line
                      key={index}
                      {...getSuggestionItemProps(suggestion, { style })}
                    >
                      {suggestion.description}
                    </div>
                  );
                })
              )}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </GridItem>
  );
}

export default GoogleAutocomplete;
