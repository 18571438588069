import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select, { components } from 'react-select';

const ControlComponent = props => {
  return (
    <components.Control
      className={classNames(
        'CustomSelect',
        props.selectProps.fullWidth ? 'FullWidth' : null
      )}
      {...props}
    />
  );
};

const CustomOption = props => {
  return <components.Option className='CustomOption' {...props} />;
};

const Menu = props => {
  return (
    <components.Menu {...props} className='CustomMenu'>
      {props.children}
    </components.Menu>
  );
};

class CustomSelect extends Component {
  render() {
    return (
      <div
        className={classNames(
          'Wrapper',
          this.props.fullWidth ? 'FullWidthWrapper' : null
        )}
      >
        <Select
          components={{
            Control: ControlComponent,
            Option: CustomOption,
            Menu: Menu,
          }}
          options={this.props.options}
          placeholder={this.props.placeholder}
          value={{ value: this.props.value, label: this.props.label }}
          isSearchable={true}
          onChange={this.props.onChange}
          fullWidth={this.props.fullWidth}
        />
      </div>
    );
  }
}

CustomSelect.propTypes = {
  value: PropTypes.node,
  label: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
};

export default CustomSelect;
