import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Formik, Form } from 'formik';
import MenuItem from '@mui/material/MenuItem';
import { RootState } from '../../store/config/types';
import { LocationLatLng } from '../../store/config/types/location.types';
import { Transshipment } from '../../store/config/types/transshipment.types';
import * as actions from '../../store/actions/toast.actions';
import {
  createTransshipment,
  updateTransshipment,
  fetchTransshipments,
} from '../../store/actions/transshipments.actions';
import schemas from '../../utils/schemas';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import Button from '../../components/CustomButtons/Button';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import InputField from '../../components/SpecialInput/InputField';
import GoogleAutocomplete from '../../components/SpecialInput/GoogleAutocomplete/GoogleAutocomplete';
import SelectField from '../../components/SpecialInput/SelectField';
import Spinner from '../../components/Spinner/Spinner';

interface TransshipmentParams {
  transshipmentId?: string;
}

interface TransshipmentValues {
  transshipmentId?: number;
  description: string;
  streetAddress: string;
  availability: boolean;
  cost: number | null;
}

const mapStateToProps = (state: RootState) => {
  return {
    transshipments: state?.transshipments,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  fetchTransshipments: () => dispatch(fetchTransshipments()),
  onCreateTransshipment: (transshipment: Transshipment) => dispatch(createTransshipment(transshipment)),
  onUpdateTransshipment: (transshipmentId: number, transshipment: Transshipment) =>
    dispatch(updateTransshipment(transshipmentId, transshipment)),
  setToast: (message: string, messageType: string) => dispatch(actions.setToast(message, messageType)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const TransshipmentForm = ({
  onUpdateTransshipment,
  onCreateTransshipment,
  transshipments,
  fetchTransshipments,
  setToast,
}: PropsFromRedux) => {
  const { transshipmentId } = useParams<TransshipmentParams>();
  const history = useHistory();
  const editMode: boolean = Boolean(transshipmentId);
  const [selectedTransshipment, setSelectedTransshipment] = useState<Transshipment | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [address, setAddress] = useState<string>('');
  const [coordinates, setCoordinates] = useState<LocationLatLng>({ lat: null, lng: null });
  const [city, setCity] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [zipCode, setZipCode] = useState<string>('');

  useEffect(() => {
    if (!transshipments.transshipments) fetchTransshipments();
    if (editMode && selectedTransshipment === null && transshipments.transshipments && transshipmentId) {
      const editedTransshipment = transshipments.transshipments.filter(
        (t: Transshipment) => t.transshipmentId === Number(transshipmentId),
      )[0];
      setSelectedTransshipment(editedTransshipment);
      setAddress(editedTransshipment?.location?.streetAddress!);
    }
  }, [
    editMode,
    selectedTransshipment,
    transshipments.transshipments,
    transshipmentId,
    setSelectedTransshipment,
    fetchTransshipments,
  ]);

  useEffect(() => {
    if (submitting) {
      if (transshipments.createTransshipmentSuccess || transshipments.updateTransshipmentSuccess) {
        setSubmitting(false);
        setToast(
          `Transshipment successfully ${transshipments.createTransshipmentSuccess ? 'created' : 'updated'}`,
          'success',
        );
        history.push('/transshipment-locations');
      } else if (transshipments.createTransshipmentErrorMessage || transshipments.updateTransshipmentErrorMessage) {
        setSubmitting(false);
        setToast(
          `An error has ocurred while ${
            transshipments.createTransshipmentErrorMessage ? 'creating' : 'updating'
          } transshipment`,
          'danger',
        );
      }
    }
  }, [
    submitting,
    transshipments.createTransshipmentSuccess,
    transshipments.updateTransshipmentSuccess,
    transshipments.createTransshipmentErrorMessage,
    transshipments.updateTransshipmentErrorMessage,
    history,
    setSubmitting,
    setToast,
  ]);

  const handleGoBack = () => history.push('/transshipment-locations');

  const submitForm = async (values: TransshipmentValues) => {
    const transshipmentRequest: Transshipment = {
      transshipmentId: values.transshipmentId!,
      description: values.description,
      location: {
        latitude: coordinates.lat!,
        longitude: coordinates.lng!,
        streetAddress: address,
        city: city,
        state: state,
        zipCode: zipCode,
      },
      availability: values.availability,
      cost: values.cost!,
    };

    setSubmitting(true);

    if (editMode) {
      await onUpdateTransshipment(Number(transshipmentId), transshipmentRequest);
    } else {
      await onCreateTransshipment(transshipmentRequest);
    }
  };

  if (editMode && !selectedTransshipment) {
    return <Spinner />;
  }

  return (
    <div className="courier-form">
      <Card profile>
        <CardHeader color="primary" className="card-header">
          {editMode && selectedTransshipment ? (
            <h4>Update transshipment location</h4>
          ) : (
            <h4>New transshipment location</h4>
          )}
        </CardHeader>
        <CardBody profile>
          <Formik
            initialValues={{
              description: selectedTransshipment ? selectedTransshipment.description! : '',
              streetAddress: selectedTransshipment ? selectedTransshipment?.location?.streetAddress : '',
              availability: selectedTransshipment ? selectedTransshipment.availability! : true,
              cost: selectedTransshipment ? selectedTransshipment.cost! : null,
              shipperId: transshipmentId || '',
            }}
            validationSchema={schemas.TransshipmentSchema}
            onSubmit={submitForm}
          >
            {({ setFieldValue }) => (
              <Form>
                <h3 className="section-title">Transshipment info</h3>
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <InputField placeholder="Description" name="description" type="text" />
                  </GridItem>
                  <GoogleAutocomplete
                    address={address}
                    setAddress={setAddress}
                    setCoordinates={setCoordinates}
                    setCity={setCity}
                    setState={setState}
                    setZipCode={setZipCode}
                    setFieldValue={setFieldValue}
                    usesAllGoogleProps={true}
                    inputName={'streetAddress'}
                    placeholderName={'Address'}
                    inputSize={6}
                  />
                  <GridItem xs={12} sm={6}>
                    <SelectField placeholder="Availability" name="availability">
                      <MenuItem value="true">Available</MenuItem>
                      <MenuItem value="false">Not Available</MenuItem>
                    </SelectField>
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <InputField placeholder="Cost" name="cost" type="number" />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12}>
                    <div className="btn-group">
                      <Button type="submit" className="submit-button" color="primary">
                        {`${editMode ? 'Update' : 'Create'} transshipment`}
                      </Button>
                      <Button onClick={handleGoBack} className="cancel-button" color="secondary">
                        Cancel
                      </Button>
                    </div>
                  </GridItem>
                </GridContainer>
              </Form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </div>
  );
};

export default connector(TransshipmentForm);
