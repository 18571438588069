import { LocationHistory, Vehicle } from '../store/config/types';
import { VehicleEnum } from '../store/config/enums/vehicle.enum';

export const vehicleListConcat = (vehicle: Vehicle): string => {
  if (vehicle) {
    switch (vehicle.vehicleType) {
      case VehicleEnum.BIKE:
      case VehicleEnum.WALKER:
        return VehicleEnum[vehicle.vehicleType];
      default:
        let returnValue = '';
        returnValue += vehicle.vehicleType ? VehicleEnum[vehicle.vehicleType || VehicleEnum.CAR] + '-' : '';
        returnValue += vehicle.model ? vehicle.model + '-' : '';
        returnValue += vehicle.color ? vehicle.color + '-' : '';
        returnValue += vehicle.licensePlate ? vehicle.licensePlate : '';
        return returnValue;
    }
  } else {
    return 'N/D';
  }
};

export const googleMapsLink = (location: LocationHistory): string => {
  if (location) {
    const latitude = location.latitude;
    const longitude = location.longitude;
    return `https://maps.google.com/?q=${latitude},${longitude}`;
  } else {
    return 'N/D';
  }
};

export const maskPhone = (phone: string | undefined) => {
  if (phone?.length === 10) {
    return `(${phone[0]}${phone[1]}${phone[2]}) ${phone[3]}${phone[4]}${phone[5]}-${phone[6]}${phone[7]}${phone[8]}${phone[9]}`;
  }
};
