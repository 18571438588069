import { useEffect, useState, Fragment } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../store/config/types';
import * as actions from '../../store/actions/shippers.actions';
import { maskPhone } from '../../utils/string.helpers';
import TableList from '../../components/TableList/TableList';
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import SpecialInput from '../../components/SpecialInput/Input';
import AddButton from '../../components/CustomButtons/AddButton';
import Spinner from '../../components/Spinner/Spinner';
import Pagination from '../../components/Pagination/Pagination';
import CustomDialog from '../../components/CustomDialog/CustomDialog';

const PAGE_LIMIT: number = 12;

const mapStateToProps = (state: RootState) => {
  return {
    shippers: state.shippers,
    loggedIn: state.auth.loggedIn,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  onFetchShippers: () => dispatch(actions.fetchShippers()),
  onDeleteShipper: (shipperId: number) => dispatch(actions.deleteShipper(shipperId)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function Shippers({ onFetchShippers, onDeleteShipper, shippers, loggedIn }: PropsFromRedux) {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [shipperToDelete, setShipperToDelete] = useState<number | null>(null);
  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    if (
      !shippers.loadingShippers &&
      !shippers.shippers &&
      !shippers.shippersFetched &&
      !shippers.shippersErrorMessage
    ) {
      onFetchShippers();
    }
  }, [
    onFetchShippers,
    shippers.shippersFetched,
    shippers.shippers,
    shippers.shippersErrorMessage,
    shippers.loadingShippers,
    loggedIn,
  ]);

  const handlePageClick = (page: number) => setCurrentPage(page);

  const handleCreateShipper = () => history.push('/shippers/create');

  const handleUpdateShipper = (shipperId: number) => history.push(`/shippers/${shipperId}/update`);

  const handleDeleteShipper = (shipperId: number) => setShipperToDelete(shipperId);

  const deleteShipper = () => {
    onDeleteShipper(shipperToDelete!);
    setShipperToDelete(null);
  };

  const handleCloseDialog = () => setShipperToDelete(null);

  const searchTextHandler = (text: string) => setSearchText(text);

  const filters = (
    <GridContainer>
      <GridItem xs={12} sm={12} md={4}>
        <SpecialInput
          element={{
            elementType: 'input',
            elementConfig: { type: 'text', placeholder: 'Search..' },
            value: searchText,
            validation: {},
          }}
          onChange={(e) => searchTextHandler(e)}
        />
      </GridItem>
    </GridContainer>
  );

  let shippersContent = null;
  if (shippers.shippers) {
    let shippersToShow = shippers.shippers || [];

    if (searchText !== '') {
      shippersToShow = shippersToShow?.filter((shipper) => {
        return ['firstName', 'lastName', 'companyName'].some((key) => {
          return (shipper as any)[key]?.toLowerCase().includes(searchText.toLowerCase());
        });
      });
    }

    const options = shippersToShow.slice((currentPage - 1) * PAGE_LIMIT, currentPage * PAGE_LIMIT).map((shipper) => {
      return [
        shipper.shipperId,
        shipper.companyName || '-',
        shipper.firstName,
        shipper.lastName,
        shipper.homeAddress,
        maskPhone(shipper.phoneNumber),
        shipper.email,
      ];
    });

    shippersContent = (
      <TableList
        title="Shippers"
        head={['Id', 'Company', 'First Name', 'Last Name', 'Home address', 'Phone number', 'Email', '']}
        onEdit={handleUpdateShipper}
        onDelete={handleDeleteShipper}
        edit // TODO try to remove this once services are wired up again
        delete
        filters={filters}
        data={options}
        pagination={
          <Pagination
            totalRecords={shippersToShow?.length}
            pageLimit={PAGE_LIMIT}
            pageNeighbours={10}
            onPageChanged={handlePageClick}
          />
        }
      />
    );
  } else if (shippers.loadingShippers) {
    shippersContent = <Spinner />;
  } else {
    shippersContent = <div>No data to show</div>;
  }

  return (
    <Fragment>
      {shippersContent}
      <AddButton onClick={handleCreateShipper} />
      <CustomDialog
        title={'Confirm'}
        description={'¿Are you sure you want to delete this shipper?'}
        open={shipperToDelete !== null}
        onConfirmation={deleteShipper}
        handleClose={handleCloseDialog}
        okButtonText={'Yes'}
        cancelButtonText={'Cancel'}
      />
    </Fragment>
  );
}

export default connector(Shippers);
